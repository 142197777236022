import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CompactType, DisplayGrid, GridsterItem, GridsterItemComponent, GridsterItemComponentInterface, GridType } from '@leapxl/gridster';
import { flatten, uniqBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { BuilderService } from '../../../../core/builder/builder.service';
import { BusService } from '../../../../core/molecular/services/bus.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { ApiFileService } from '../../../../core/services/api-file.service';
import { ApiPropertiesService } from '../../../../core/services/api-properties.service';
import { ToolsService } from '../../../../core/services/tools.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { Constants } from '../../../constants';
import { PropertyVersioningDto } from '../../../dtos/versioning-dto';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { CommunicationService } from '../../../services/communication.service';
import { DraggableWindowService } from '../../../services/draggable-window.service';
import { BadgeMoleculeComponent } from '../../badge-molecule/badge-molecule.component';
import { IRepresentativeMolecule } from '../../interfaces/representative-molecule.interface';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';
import { BreadcrumbMoleculeComponent } from '../breadcrumb-molecule/breadcrumb-molecule.component';
import { ButtonMoleculeComponent } from '../button-molecule/button-molecule.component';
import { ChartMoleculeComponent } from '../chart-molecule/chart-molecule.component';
import { CheckboxMoleculeComponent } from '../checkbox-molecule/checkbox-molecule.component';
import { CustomMoleculeComponent } from '../custom-molecule/custom-molecule.component';
import { DatepickerMoleculeComponent } from '../datepicker-molecule/datepicker-molecule.component';
import { DropdownMoleculeComponent } from '../dropdown-molecule/dropdown-molecule.component';
import { H1MoleculeComponent } from '../h1-molecule/h1-molecule.component';
import { H2MoleculeComponent } from '../h2-molecule/h2-molecule.component';
import { H3MoleculeComponent } from '../h3-molecule/h3-molecule.component';
import { H4MoleculeComponent } from '../h4-molecule/h4-molecule.component';
import { H5MoleculeComponent } from '../h5-molecule/h5-molecule.component';
import { IconMoleculeComponent } from '../icon-molecule/icon-molecule.component';
import { IframeMoleculeComponent } from '../iframe-molecule/iframe-molecule.component';
import { ImageMoleculeComponent } from '../image-molecule/image-molecule.component';
import { LabelMoleculeComponent } from '../label-molecule/label-molecule.component';
import { ProgressMoleculeComponent } from '../progress-molecule/progress-molecule.component';
import { QrCodeMoleculeComponent } from '../qrcode-molecule/qrcode-molecule.component';
import { RadioMoleculeComponent } from '../radio-molecule/radio-molecule.component';
import { SliderMoleculeComponent } from '../slider-molecule/slider-molecule.component';
import { StepperMoleculeComponent } from '../stepper-molecule/stepper-molecule.component';
import { TableMoleculeComponent } from '../table-molecule/table-molecule.component';
import { TextareaMoleculeComponent } from '../textarea-molecule/textarea-molecule.component';
import { TextboxMoleculeComponent } from '../textbox-molecule/textbox-molecule.component';

declare const Magnet;

@Component({
  selector: 'app-workgroup-molecule',
  templateUrl: './workgroup-molecule.component.html',
  styleUrls: ['./workgroup-molecule.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ transform: 'scale(0)', opacity: 0 }), animate('100ms', style({ transform: 'scale(1)', opacity: 1 }))]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
    ]),
    trigger('flyInOut', [
      transition('void => fly', [
        animate(300, keyframes([style({ transform: 'translateY(50%)', opacity: 0 }), style({ transform: 'translateY(0)', opacity: 1 })])),
      ]),
    ]),
    trigger('flyInOut', [transition('void => fade', [animate(3000, keyframes([style({ opacity: 0 }), style({ opacity: 1 })]))])]),
    trigger('fade', [transition('void => *', [style({ opacity: 0 }), animate(500)]), transition('* => void', [animate(200, style({ opacity: 0 }))])]),
    trigger('fadeIn', [transition(':enter', [style({ opacity: '0' }), animate('.7s ease-out', style({ opacity: '1' }))])]),
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WorkgroupMoleculeComponent extends BaseMoleculeComponent implements OnInit, OnDestroy {
  children: IRepresentativeMolecule[] = [];
  subscription: Subscription;
  elementsPushed = [];
  environment = environment;
  dragStart = {};
  sizeStart = {};
  animation = '';
  ImgSource = '';
  debug = false;
  displayGrid = false;
  gridSize = 0;
  gridCols = [];
  gridRows = [];
  mouseIn = false;
  ruleNumbersX = [];
  ruleNumbersY = [];
  enableRule = false;
  rulerCurColWidth = 0;
  rulerCurRowHeight = 0;
  customGuidelinesX = [];
  customGuidelinesY = [];
  runningGridsterConfig = {
    collision: false,
    gridType: GridType.Fit,
    compactType: CompactType.None,
    margin: 0,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 0,
    minCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    maxCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    minRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    maxRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    maxItemCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    maxItemRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    minItemRows: 1,
    maxItemArea: 200000,
    minItemArea: 1,
    defaultItemCols: 3,
    defaultItemRows: 3,
    fixedColWidth: 20,
    fixedRowHeight: 20,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 0,
    scrollSpeed: 0,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    swap: false,
    pushItems: false,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: {
      north: false,
      east: false,
      south: false,
      west: false,
    },
    pushResizeItems: false,
    displayGrid: DisplayGrid.Always,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false,
  };
  
  // region GRIDSTER CONFIG
  editorGridsterConfig = {
    dragStartCallback: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
      this.workAreaService.mobileEmulatorSize = 'normal';
      this.workAreaService.RunMobileTest(false);
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    },
    collision: false,
    gridType: GridType.Fit,
    compactType: CompactType.None,
    margin: 0,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 0,
    minCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    maxCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    minRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    maxRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    maxItemCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    maxItemRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    minItemCols: 1,
    minItemRows: 1,
    maxItemArea: 200000,
    minItemArea: 1,
    defaultItemCols: 3,
    defaultItemRows: 3,
    fixedColWidth: 20,
    fixedRowHeight: 20,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 0,
    scrollSpeed: 0,
    enableEmptyCellClick: true,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: true,
    itemChangeCallback: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
      // console.log('item changed', item, itemComponent);
      if (item.change === 'dragStart') {
        // this.workAreaService.HideElementFocusedMenu();
        this.workAreaService.elementClicked = this.busService.Get(item.id);
      }
      if (item.change === 'push') {
        if (this.elementsPushed.findIndex(e => e.id === item.id) === -1) {
          this.elementsPushed.push(item);
        }
        this.Throttle(
          (func, delay, context) => {
            const properties = [];
            
            this.elementsPushed.forEach(element => {
              properties.push(
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'x',
                  value: element.x,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Position Changed`,
                  name: 'Position - X',
                }),
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'y',
                  value: element.y,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Position Changed`,
                  name: 'Position - Y',
                }),
              );
            });
            
            this.angularZone.runOutsideAngular(() => {
              this.propertiesService.SaveProperties(properties).subscribe();
            });
            this.elementsPushed = [];
          },
          1000,
          this,
          item,
        );
      }
    },
    emptyCellClickCallback: (event: MouseEvent, item: GridsterItem) => {
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
      this.workAreaService.mobileEmulatorSize = 'normal';
      this.workAreaService.RunMobileTest(false);
      this.workAreaService.EndSettingTabOrder();
      // this.workAreaService.draggableWindow.forEach((dw) => {
      //   dw.Hide();
      // });
      // this.workAreaService.draggableWindow = [];
      // this.workAreaService.ShowElementFocusedMenu(this.context, event);
      // this.workAreaService.elementClicked = this.busService.Get(this.context.Id.toString());
      // console.log('empty cell callback');
    },
    emptyCellDropCallback: (event: any, position: GridsterItem) => {
      console.log('drop');
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
      // console.log('position', position);
      
      if (this.workAreaService.actualEditorViews.map(v => v.id).includes(this.context.Properties.view)) {
        this.OnDrop(this.dragService.dragData, event, position);
      } else {
        this.snackerService.ShowMessageOnBottom(
          `Change to [${ this.cobbleService.Cobble.properties.views.find(v => v.id === this.context.Properties.view).name }] View to drop elements`,
          'layers',
        );
      }
    },
    enableEmptyCellDrag: true,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    onResize: (event: any, gridsterItem: GridsterItemComponent, cols: number, rows: number, newX: number, newY: number) => {
      const elementData = this.sizeStart[gridsterItem.item.id];
      elementData.preview.classList.add('moving');
      const activateGuidelines = this.workAreaService.editorPreferences.guidelines || (event && (event.metaKey || event.ctrlKey));
      
      if (!activateGuidelines) {
        return;
      }
      
      // avoid if resize from top or left
      if (newX || newY) {
        return;
      }
      
      elementData.coordinates.classList.remove('hide-element');
      let newCols = cols;
      let newRows = rows;
      const radio = 1;
      const diameter = radio * 2;
      const leftX = elementData.x + cols - diameter;
      const rightX = elementData.x + cols + diameter;
      const topY = elementData.y + rows - diameter;
      const bottomY = elementData.y + rows + diameter;
      const closeYEdges = elementData.yEdges.filter(e => e.value >= topY && e.value <= bottomY);
      const closeXEdges = elementData.xEdges.filter(e => e.value >= leftX && e.value <= rightX);
      
      closeXEdges.forEach(edge => {
        if (Math.abs(elementData.x + newCols - edge.value) <= radio) {
          elementData.element.ResponsiveProperties().cols =
            edge.value - elementData.x > this.context.ResponsiveProperties().cols
              ? this.context.ResponsiveProperties().cols - elementData.x
              : edge.value - elementData.x;
          elementData.element.ResponsiveProperties().rows =
            elementData.element.ResponsiveProperties().y + newRows > this.context.ResponsiveProperties().rows
              ? this.context.ResponsiveProperties().rows - elementData.element.ResponsiveProperties().x
              : newRows;
          newCols = elementData.element.ResponsiveProperties().cols;
          
          this.context.GridsterConfig.api.optionsChanged();
          elementData.rightGuideline = true;
        }
      });
      
      closeYEdges.forEach(edge => {
        if (Math.abs(elementData.y + newRows - edge.value) <= radio) {
          elementData.element.ResponsiveProperties().rows =
            edge.value - elementData.y > this.context.ResponsiveProperties().rows
              ? this.context.ResponsiveProperties().rows - elementData.y
              : edge.value - elementData.y;
          elementData.element.ResponsiveProperties().cols =
            elementData.element.ResponsiveProperties().x + newCols > this.context.ResponsiveProperties().cols
              ? this.context.ResponsiveProperties().cols - elementData.element.ResponsiveProperties().x
              : newCols;
          newRows = elementData.element.ResponsiveProperties().rows;
          
          this.context.GridsterConfig.api.optionsChanged();
          elementData.bottomGuideline = true;
        }
      });
      
      elementData.coordinates.children[0].innerHTML = `W:${ newCols } H:${ newRows }`;
      
      if (elementData.rightGuideline) {
        elementData.eHandle.classList.add('guideline-vertical');
        elementData.preview.classList.add('guideline');
        
        if (!closeXEdges.map(e => e.value).includes(elementData.x + newCols)) {
          elementData.rightGuideline = false;
          elementData.eHandle.classList.remove('guideline-vertical');
          elementData.preview.classList.remove('guideline');
        }
      }
      
      if (elementData.bottomGuideline) {
        elementData.sHandle.classList.add('guideline-horizontal');
        elementData.preview.classList.add('guideline');
        
        if (!closeYEdges.map(e => e.value).includes(elementData.y + newRows)) {
          elementData.bottomGuideline = false;
          elementData.sHandle.classList.remove('guideline-horizontal');
          elementData.preview.classList.remove('guideline');
        }
      }
    },
    onDrag: (event: MouseEvent, gridsterItem: GridsterItemComponent, positionX: number, positionY: number) => {
      const elementData = this.dragStart[gridsterItem.item.id];
      elementData.leftGuideline = false;
      elementData.rightGuideline = false;
      elementData.topGuideline = false;
      elementData.bottomGuideline = false;
      elementData.horizontalGuidelineCenter = false;
      elementData.verticalGuidelineCenter = false;
      
      elementData.wHandle.classList.remove('guideline-vertical');
      elementData.eHandle.classList.remove('guideline-vertical');
      elementData.nHandle.classList.remove('guideline-horizontal');
      elementData.sHandle.classList.remove('guideline-horizontal');
      elementData.seHandle.classList.remove('guideline-corner');
      elementData.neHandle.classList.remove('guideline-corner');
      elementData.swHandle.classList.remove('guideline-corner');
      elementData.nwHandle.classList.remove('guideline-corner');
      
      elementData.preview.classList.add('moving');
      elementData.moved = true;
      
      // region DRAG GROUP
      
      if (this.workAreaService.elementsSelected.length > 1) {
        if (!this.workAreaService.editorPreferences.groupDrag) {
          return;
        }
        
        const data = this.dragStart[gridsterItem.item.id];
        const xChange = data.x - positionX;
        const yChange = data.y - positionY;
        
        data.element.ResponsiveProperties().x = positionX > data.limitX ? data.limitX : positionX;
        data.element.ResponsiveProperties().y = positionY > data.limitY ? data.limitX : positionY;
        
        data.selection.forEach(s => {
          const newX = this.dragStart[s.Id].x - xChange;
          const newY = this.dragStart[s.Id].y - yChange;
          
          s.ResponsiveProperties().x = newX <= 0 ? 0 : newX >= this.dragStart[s.Id].limitX ? this.dragStart[s.Id].limitX : newX;
          s.ResponsiveProperties().y = newY <= 0 ? 0 : newY >= this.dragStart[s.Id].limitY ? this.dragStart[s.Id].limitY : newY;
        });
        
        if (this.context.GridsterConfig.api) {
          this.context.GridsterConfig.api.optionsChanged();
        }
      }
      // endregion
      else {
        const activateGuidelines = this.workAreaService.editorPreferences.guidelines || (event && (event.metaKey || event.ctrlKey));
        
        if (!activateGuidelines) {
          console.log('nop');
          return;
        }
        
        // region VARIABLES
        const cornerClass = 'guideline-corner';
        const radio = 2;
        elementData.coordinates.classList.remove('hide-element');
        elementData.element.IsMoving = true;
        const diameter = radio * 2;
        
        let allXEdges = [];
        let allYEdges = [];
        
        if (this.workAreaService.editorPreferences.gridline) {
          allXEdges = this.gridCols;
          allYEdges = this.gridRows;
        } else if (this.workAreaService.editorPreferences.customGuideline) {
          allXEdges = this.customGuidelinesX;
          allYEdges = this.customGuidelinesY;
        } else if (activateGuidelines) {
          allXEdges = elementData.xEdges;
          allYEdges = elementData.yEdges;
        }
        
        // allXEdges = elementData.xEdges.concat(this.gridCols).concat(this.customGuidelinesX);
        // allYEdges = elementData.xEdges.concat(this.gridRows).concat(this.customGuidelinesY);
        const closeYEdges = allYEdges.filter(
          e =>
            (e.value >= positionY - diameter && e.value <= positionY + diameter) ||
            (e.value >= positionY + elementData.rows - diameter && e.value <= positionY + elementData.rows + diameter),
        );
        const closeXEdges = allXEdges.filter(
          e =>
            (e.value >= positionX - diameter && e.value <= positionX + diameter) ||
            (e.value >= positionX + elementData.cols - diameter && e.value <= positionX + elementData.cols + diameter),
        );
        
        let newX = positionX;
        let newY = positionY;
        // endregion
        
        newY =
          newY + elementData.rows > this.context.ResponsiveProperties().rows ? this.context.ResponsiveProperties().rows - elementData.rows : newY;
        let closestY = 999;
        let closestX = 999;
        
        closeXEdges.forEach(edge => {
          if (edge.type === 'edge' && Math.abs(positionX + elementData.cols - edge.value) <= radio) {
            if (Math.abs(positionY - edge.y) < closestY) {
              closestY = edge.y;
              elementData.element.ResponsiveProperties().x = edge.value - elementData.cols;
              newX = elementData.element.ResponsiveProperties().x;
              this.context.GridsterConfig.api.optionsChanged();
            }
            
            elementData.element.ResponsiveProperties().y = newY;
            elementData.rightGuideline = true && edge.type !== 'grid';
          }
          
          if (Math.abs(positionX - edge.value) <= radio) {
            elementData.element.ResponsiveProperties().x = edge.value;
            elementData.element.ResponsiveProperties().y = newY;
            newX = elementData.element.ResponsiveProperties().x;
            this.context.GridsterConfig.api.optionsChanged();
            elementData.leftGuideline = edge.type !== 'grid';
            
            elementData.verticalGuidelineCenter = edge.type === 'center';
            
            if (elementData.verticalGuidelineCenter) {
              elementData.verticalGuidelineCenterId = edge.id;
            }
          }
        });
        
        newX =
          newX + elementData.cols > this.context.ResponsiveProperties().cols ? this.context.ResponsiveProperties().cols - elementData.cols : newX;
        
        closeYEdges.forEach(edge => {
          if (edge.type === 'edge' && Math.abs(positionY + elementData.rows - edge.value) <= radio) {
            if (Math.abs(positionX - edge.x) < closestX || edge.x === undefined) {
              closestX = edge.x;
              elementData.element.ResponsiveProperties().y = edge.value - elementData.rows;
              newY = elementData.element.ResponsiveProperties().y;
              this.context.GridsterConfig.api.optionsChanged();
            }
            
            elementData.element.ResponsiveProperties().x = newX;
            elementData.bottomGuideline = true && edge.type !== 'grid';
            elementData.horizontalGuidelineCenter = false;
          }
          
          if (Math.abs(positionY - edge.value) <= radio) {
            elementData.element.ResponsiveProperties().x = newX;
            elementData.element.ResponsiveProperties().y = edge.value;
            newY = elementData.element.ResponsiveProperties().y;
            this.context.GridsterConfig.api.optionsChanged();
            elementData.horizontalGuidelineCenter = edge.type === 'center';
            elementData.topGuideline = !elementData.horizontalGuidelineCenter;
            if (elementData.horizontalGuidelineCenter) {
              elementData.horizontalGuidelineCenterId = edge.id;
            }
          }
        });
        
        elementData.coordinates.children[0].innerHTML = `X:${ newX } Y:${ newY }`;
        
        // if gridline is active does not need to show guidelines
        if (this.workAreaService.editorPreferences.gridline) {
          return;
        }
        
        // region DRAW GUIDELINES
        const centerMolecule = document.querySelector(`.molecule-center-guideline`);
        if (centerMolecule) {
          centerMolecule.classList.remove('molecule-center-guideline');
        }
        
        if (elementData.horizontalGuidelineCenter) {
          elementData.bottomGuideline = false;
          elementData.nHandle.classList.add('guideline-horizontal');
          elementData.nHandle.classList.add('center-guideline');
          const horizontalGuidelineCenterId = document.querySelector(`#gridsterItem-${ elementData.horizontalGuidelineCenterId }`);
          
          if (horizontalGuidelineCenterId) {
            horizontalGuidelineCenterId.classList.add('molecule-center-guideline');
          }
        } else {
          elementData.nHandle.classList.remove('guideline-horizontal');
          elementData.nHandle.classList.remove('center-guideline');
        }
        
        if (elementData.leftGuideline) {
          console.log('leftGuideline');
          
          elementData.wHandle.classList.add('guideline-vertical');
          
          if (!closeXEdges.map(e => e.value).includes(newX)) {
            elementData.leftGuideline = false;
            elementData.wHandle.classList.remove('guideline-vertical');
            elementData.wHandle.classList.remove('center-guideline');
          } else {
            if (elementData.verticalGuidelineCenter) {
              elementData.rightGuideline = false;
              elementData.wHandle.classList.add('center-guideline');
              document.querySelector(`#gridsterItem-${ elementData.verticalGuidelineCenterId }`).classList.add('molecule-center-guideline');
            } else {
              elementData.wHandle.classList.remove('center-guideline');
            }
          }
        }
        
        if (elementData.rightGuideline) {
          console.log('rightGuideline');
          
          elementData.eHandle.classList.add('guideline-vertical');
          
          if (
            !closeXEdges
            .filter(e => e.type !== 'center')
            .map(e => e.value)
            .includes(newX + elementData.element.ResponsiveProperties().cols)
          ) {
            elementData.rightGuideline = false;
            elementData.eHandle.classList.remove('guideline-vertical');
          }
        }
        
        if (elementData.topGuideline) {
          elementData.nHandle.classList.add('guideline-horizontal');
          
          if (!closeYEdges.map(e => e.value).includes(newY)) {
            elementData.topGuideline = false;
            elementData.nHandle.classList.remove('guideline-horizontal');
            elementData.nHandle.classList.remove('center-guideline');
          } else {
            if (elementData.horizontalGuidelineCenter) {
              elementData.nHandle.classList.add('center-guideline');
              document.querySelector(`#gridsterItem-${ elementData.horizontalGuidelineCenterId }`).classList.add('molecule-center-guideline');
            } else {
              elementData.nHandle.classList.remove('center-guideline');
            }
          }
          
          if (elementData.topGuideline) {
            // corners
            elementData.nwHandleAdd = elementData.leftGuideline && !elementData.verticalGuidelineCenter && !elementData.horizontalGuidelineCenter;
            elementData.neHandleAdd = elementData.rightGuideline && !elementData.horizontalGuidelineCenter && !elementData.verticalGuidelineCenter;
            // corners
          }
        } else {
          elementData.neHandleAdd = false;
          elementData.nwHandleAdd = false;
        }
        
        if (elementData.bottomGuideline) {
          elementData.sHandle.classList.add('guideline-horizontal');
          
          if (
            !closeYEdges
            .filter(e => e.type !== 'center')
            .map(e => e.value)
            .includes(newY + elementData.element.ResponsiveProperties().rows)
          ) {
            elementData.bottomGuideline = false;
            elementData.sHandle.classList.remove('guideline-horizontal');
          }
          
          if (elementData.bottomGuideline) {
            // corners
            elementData.swHandleAdd = elementData.leftGuideline && !elementData.verticalGuidelineCenter;
            elementData.seHandleAdd = elementData.rightGuideline && !elementData.horizontalGuidelineCenter;
            // corners
          }
        } else {
          elementData.seHandleAdd = false;
          elementData.swHandleAdd = false;
        }
        
        if (elementData.horizontalGuidelineCenter) {
          elementData.nHandle.classList.add('guideline-horizontal');
          elementData.nHandle.classList.add('center-guideline');
          document.querySelector(`#gridsterItem-${ elementData.horizontalGuidelineCenterId }`).classList.add('molecule-center-guideline');
        }
        
        if (elementData.seHandleAdd) {
          elementData.seHandle.classList.add(cornerClass);
        } else if (elementData.seHandle.classList.contains(cornerClass)) {
          elementData.seHandle.classList.remove(cornerClass);
        }
        
        if (elementData.swHandleAdd) {
          elementData.swHandle.classList.add(cornerClass);
        } else if (elementData.swHandle.classList.contains(cornerClass)) {
          elementData.swHandle.classList.remove(cornerClass);
        }
        
        if (elementData.neHandleAdd) {
          elementData.neHandle.classList.add(cornerClass);
        } else if (elementData.neHandle.classList.contains(cornerClass)) {
          elementData.neHandle.classList.remove(cornerClass);
        }
        
        if (elementData.nwHandleAdd) {
          elementData.nwHandle.classList.add(cornerClass);
        } else if (elementData.nwHandle.classList.contains(cornerClass)) {
          elementData.nwHandle.classList.remove(cornerClass);
        }
        // endregion
        
        // evaluating only rep mols (old method)
        if (false) {
          const top = positionY;
          const left = positionX;
          const right = positionX + elementData.cols;
          const bottom = positionY + elementData.rows;
          
          elementData.siblings.forEach(s => {
            const position = s.ResponsiveProperties();
            
            const topS = position.y - radio;
            const leftS = position.x - radio;
            const rightS = position.x + position.cols + radio;
            const bottomS = position.y + position.rows + radio;
            
            const leftC = leftS - right;
            const rightC = rightS - left;
            const topC = topS - bottom;
            const bottomC = bottomS - top;
            
            if (leftS < right && rightS > left && topS < bottom && bottomS > top) {
              console.log('left', leftS - right);
              console.log('right', rightS - left);
              console.log('top', topS - bottom);
              console.log('bottom', bottomS - top);
              
              console.log('collision detected!');
              // closest.push(s);
              
              if (
                Math.abs(leftC) < Math.abs(rightC) &&
                Math.abs(leftC) < Math.abs(topC) &&
                Math.abs(leftC) < Math.abs(bottomC) &&
                Math.abs(leftC) <= radio * 2
              ) {
                console.log('magnet');
                document.querySelector(`#gridsterItem-${ gridsterItem.item.id } .gridster-item-resizable-handler.handle-e`).classList.add('guideline');
                elementData.element.ResponsiveProperties().x = leftS - elementData.cols + radio;
                elementData.element.ResponsiveProperties().y = positionY;
                if (this.context.GridsterConfig.api) {
                  this.context.GridsterConfig.api.optionsChanged();
                }
              } else {
                console.log('remove');
                document
                .querySelector(`#gridsterItem-${ gridsterItem.item.id } .gridster-item-resizable-handler.handle-e`)
                .classList.remove('guideline');
              }
            }
          });
        }
      }
    },
    draggable: {
      enabled: true,
      stop: (element: any, itemComponent: any, event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        window['dragEnabled'] = true;
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        
        const molecule = this.busService.Get(element.id);
        molecule.IsMoving = false;
        if (this.dragStart[element.id].moved) {
          //   this.dragStart[element.id].coordinates.classList.add('hide-element');
          // this.dragStart[element.id].wHandle.classList.remove('guideline-vertical');
          // this.dragStart[element.id].eHandle.classList.remove('guideline-vertical');
          // this.dragStart[element.id].nHandle.classList.remove('guideline-horizontal');
          // this.dragStart[element.id].sHandle.classList.remove('guideline-horizontal');
          // this.dragStart[element.id].seHandle.classList.remove('guideline-corner');
          // this.dragStart[element.id].neHandle.classList.remove('guideline-corner');
          // this.dragStart[element.id].swHandle.classList.remove('guideline-corner');
          // this.dragStart[element.id].nwHandle.classList.remove('guideline-corner');
          
          setTimeout(() => {
            if (
              molecule.ParentId === this.context.Id &&
              (molecule.ResponsiveProperties().x !== this.dragStart[element.id].x ||
                molecule.ResponsiveProperties().y !== this.dragStart[element.id].y)
            ) {
              const elementsToSave = this.workAreaService.elementsSelected.length > 1 ? this.workAreaService.elementsSelected : [molecule];
              const propertiesToSave = [];
              
              this.angularZone.runOutsideAngular(() => {
                elementsToSave.forEach(es => {
                  propertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: es.Id.toString(),
                      property: 'x',
                      value: es.ResponsiveProperties().x,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: `Position Changed`,
                      name: 'Position - X',
                    }),
                  );
                  propertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: es.Id.toString(),
                      property: 'y',
                      value: es.ResponsiveProperties().y,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: `Position Changed`,
                      name: 'Position - Y',
                    }),
                  );
                  
                  const position = {
                    x: es.ResponsiveProperties().x,
                    y: es.ResponsiveProperties().y,
                    cols: es.ResponsiveProperties().cols,
                    rows: es.ResponsiveProperties().rows,
                  };
                  
                  this.workAreaService.elementsSelectedOriginalPosition[molecule.Id] = position;
                  delete this.dragStart[es.Id];
                });
                
                this.propertiesService.SaveProperties(propertiesToSave).subscribe();
              });
              
              // this.workAreaService.HideElementFocusedMenu();
              this.workAreaService.showElementFocusedMenu = false;
            } else {
              this.ClickOnElement(molecule, event);
            }
          }, 30);
        } else {
          this.ClickOnElement(molecule, event);
        }
        
        const centerMolecule = document.querySelector(`.molecule-center-guideline`);
        if (centerMolecule) {
          centerMolecule.classList.remove('molecule-center-guideline');
        }
        
        this.dragStart[element.id].coordinates.classList.add('hide-element');
        this.dragStart[element.id].wHandle.classList.remove('guideline-vertical');
        this.dragStart[element.id].eHandle.classList.remove('guideline-vertical');
        this.dragStart[element.id].nHandle.classList.remove('guideline-horizontal');
        this.dragStart[element.id].sHandle.classList.remove('guideline-horizontal');
        this.dragStart[element.id].seHandle.classList.remove('guideline-corner');
        this.dragStart[element.id].neHandle.classList.remove('guideline-corner');
        this.dragStart[element.id].swHandle.classList.remove('guideline-corner');
        this.dragStart[element.id].nwHandle.classList.remove('guideline-corner');
        this.dragStart[element.id].preview.classList.remove('moving');
        
        this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
      },
      start: (element: any, itemComponent: any, event: MouseEvent) => {
        console.log('init drag', event, itemComponent);
        this.workAreaService.elementClicked = this.busService.Get(element.id);
        
        if (this.workAreaService.elementClicked.Properties.dragEnabled === false) {
          window['dragEnabled'] = false;
        } else {
          window['dragEnabled'] = true;
        }
        
        this.dragService.dragBetweenWG.dragging = this.workAreaService.elementClicked.EnableDragBetweenWG;
        if (!(event.ctrlKey || event.metaKey)) {
          this.workAreaService.HideElementFocusedMenu(this.workAreaService.elementsSelected.length <= 1);
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        }
        
        this.dragService.dragBetweenWG.repMoleculeIdDragged = this.workAreaService.elementClicked.Id;
        this.dragService.dragBetweenWG.repMoleculeIdsDragged = this.workAreaService.elementsSelected.map(es => es.Id);
        if (!this.dragService.dragBetweenWG.repMoleculeIdsDragged.includes(this.dragService.dragBetweenWG.repMoleculeIdDragged)) {
          this.dragService.dragBetweenWG.repMoleculeIdsDragged.push(this.dragService.dragBetweenWG.repMoleculeIdDragged);
        }
        this.dragService.dragBetweenWG.sourceWgId = this.workAreaService.elementClicked.ParentId;
        
        const selection = this.workAreaService.elementsSelected.filter(es => es.Id !== element.id);
        const siblings = this.workAreaService.elementsSelected.length > 1 ? [] : this.busService.GetSiblings(element.id);
        
        const xEdges = siblings.map(s => {
          return [
            {
              id: s.Id,
              y: s.ResponsiveProperties().y,
              value: s.ResponsiveProperties().x,
              type: 'edge',
            },
            {
              id: s.Id,
              y: s.ResponsiveProperties().y,
              value: s.ResponsiveProperties().x + s.ResponsiveProperties().cols,
              type: 'edge',
            },
            {
              id: s.Id,
              y: s.ResponsiveProperties().y,
              value: Math.ceil(s.ResponsiveProperties().x + s.ResponsiveProperties().cols / 2) - Math.ceil(element.cols / 2),
              type: 'center',
            },
          ];
        });
        const yEdges = siblings.map(s => {
          return [
            {
              id: s.Id,
              value: s.ResponsiveProperties().y,
              type: 'edge',
            },
            {
              id: s.Id,
              value: s.ResponsiveProperties().y + s.ResponsiveProperties().rows,
              type: 'edge',
            },
            {
              id: s.Id,
              value: Math.ceil(s.ResponsiveProperties().y + s.ResponsiveProperties().rows / 2) - Math.ceil(element.rows / 2),
              type: 'center',
            },
          ];
        });
        
        this.dragStart[element.id] = {
          coordinates: document.querySelector(`#gridsterItem-${ element.id } .coordinates-container`),
          wHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-w`),
          eHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-e`),
          nHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-n`),
          sHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-s`),
          nwHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-nw`),
          swHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-sw`),
          neHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-ne`),
          seHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-se`),
          // xEdges: uniqBy(flatten(xEdges as any).sort(this.toolsService.CompareValues('type', 'desc')), 'value'),
          // yEdges: uniqBy(flatten(yEdges as any).sort(this.toolsService.CompareValues('type', 'desc')), 'value'),
          xEdges: flatten(xEdges as any).sort(this.toolsService.CompareValues('type', 'desc')),
          yEdges: flatten(yEdges as any).sort(this.toolsService.CompareValues('type', 'desc')),
          element: this.workAreaService.elementClicked,
          selection: selection,
          siblings: siblings,
          preview: document.querySelector(`#gridsterItem-${ this.context.Id } .workgroup-gridster > gridster-preview`),
          x: element.x,
          y: element.y,
          cols: element.cols,
          rows: element.rows,
          limitX: this.context.ResponsiveProperties().cols - this.workAreaService.elementClicked.ResponsiveProperties().cols,
          limitY: this.context.ResponsiveProperties().rows - this.workAreaService.elementClicked.ResponsiveProperties().rows,
        };
        
        console.log('drag: ' + element.id);
        
        this.workAreaService.elementsSelected.forEach(es => {
          this.workAreaService.dragStartPositions[es.Id] = {
            x: es.ResponsiveProperties().x,
            y: es.ResponsiveProperties().y,
            cols: es.ResponsiveProperties().cols,
            rows: es.ResponsiveProperties().rows,
          };
        });
        
        this.workAreaService.dragStartPositions[element.id] = {
          x: element.x,
          y: element.y,
          cols: element.cols,
          rows: element.rows,
        };
        
        console.log('x edges: ', this.dragStart[element.id].xEdges);
        selection.forEach(s => {
          this.dragStart[s.Id] = {
            limitX: this.context.ResponsiveProperties().cols - s.ResponsiveProperties().cols,
            limitY: this.context.ResponsiveProperties().rows - s.ResponsiveProperties().rows,
            x: s.ResponsiveProperties().x,
            y: s.ResponsiveProperties().y,
          };
        });
        
        const parentGridtsterItemElement = document.querySelector(`#gridsterItem-${ this.workAreaService.elementClicked.ParentId }`);
        if (parentGridtsterItemElement) {
          parentGridtsterItemElement.classList.add('element-on-top');
        }
        
        setTimeout(() => {
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
        }, 50);
      },
    },
    resizable: {
      enabled: true,
      stop: (element: any, itemComponent: any, event: MouseEvent) => {
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        console.log('element resized', element);
        const molecule = this.busService.Get(element.id);
        this.sizeStart[element.id].coordinates.classList.add('hide-element');
        this.sizeStart[element.id].eHandle.classList.remove('guideline-vertical');
        this.sizeStart[element.id].sHandle.classList.remove('guideline-horizontal');
        this.sizeStart[element.id].preview.classList.remove('guideline');
        this.sizeStart[element.id].preview.classList.remove('moving');
        
        if (molecule.Type === RepresentativeMoleculesType.Table) {
          this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(molecule);
        }
        
        if (molecule.Type === RepresentativeMoleculesType.WorkGroup || molecule.Type === RepresentativeMoleculesType.Stepper) {
          setTimeout(() => {
            this.workAreaService.ShowElementFocusedMenu(molecule);
            
            if (
              molecule.ResponsiveProperties().cols !== this.sizeStart[element.id].cols ||
              molecule.ResponsiveProperties().rows !== this.sizeStart[element.id].rows
            ) {
              const propertiesToSave: PropertyVersioningDto[] = [];
              
              // address columns
              if (molecule.ResponsiveProperties().cols !== this.sizeStart[element.id].cols) {
                let newWgCols =
                  molecule.ResponsiveProperties().cols > this.sizeStart[element.id].cols
                    ? molecule.GridsterConfig.minCols + (molecule.ResponsiveProperties().cols - this.sizeStart[element.id].cols)
                    : molecule.GridsterConfig.minCols - (this.sizeStart[element.id].cols - molecule.ResponsiveProperties().cols);
                
                // console.log('newWgCols', newWgCols);
                // console.log('element cols', element.cols);
                newWgCols = element.cols;
                
                if (!this.workAreaService.editorPreferences.resizeAll) {
                  const elementsToSave = [];
                  const colsContracted = this.sizeStart[element.id].cols - newWgCols;
                  // console.log('cols contracted', colsContracted);
                  
                  if (colsContracted > 0) {
                    const startElementsOnEdge = [];
                    for (let i = 1; i <= colsContracted; i++) {
                      const elementsResized = this.busService
                      .DirectChildrenElements(element.id)
                      .filter(e => e.ResponsiveProperties().x + e.ResponsiveProperties().cols > newWgCols)
                      .sort((a, b) =>
                        a.ResponsiveProperties().x + a.ResponsiveProperties().cols > b.ResponsiveProperties().x + b.ResponsiveProperties().cols
                          ? -1
                          : 1,
                      );
                      
                      // console.log('elements affected', elementsResized);
                      
                      const startElements = [];
                      elementsResized.forEach(e => {
                        const elementsToRight = [];
                        
                        elementsResized.forEach(eCompare => {
                          const yFinal = eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows;
                          const yStart = eCompare.ResponsiveProperties().y;
                          
                          if (
                            eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols >
                            e.ResponsiveProperties().x + e.ResponsiveProperties().cols &&
                            ((yFinal >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                              (yStart >= e.ResponsiveProperties().y && yStart <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                              (yStart <= e.ResponsiveProperties().y && yFinal >= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                              (yStart >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows))
                          ) {
                            elementsToRight.push(eCompare);
                          }
                        });
                        
                        if (elementsToRight.length === 0) {
                          startElements.push(e);
                        }
                      });
                      
                      // console.log('start elements', startElements);
                      
                      startElements.forEach(startElement => {
                        startElementsOnEdge.push(startElement);
                        let elementsChained = [
                          ...new Set([startElement].concat(this.workAreaService.GetChainedElementsWest(startElement, 1))),
                        ] as IRepresentativeMolecule[];
                        
                        // console.log('elements on chain', elementsChained);
                        
                        if (elementsChained.filter(e => e.ResponsiveProperties().x < 1).length > 0) {
                          // console.log('shrink chain');
                          
                          elementsChained = elementsChained.sort((a, b) => (a.ResponsiveProperties().x > b.ResponsiveProperties().x ? 1 : -1));
                          
                          elementsChained.forEach(elementToShrink => {
                            // console.log('=====================================================');
                            
                            const elementToShrinkPositioning = elementToShrink.ResponsiveProperties();
                            
                            const colsDifference =
                              elementToShrinkPositioning.cols -
                              (elementToShrinkPositioning.cols * (this.sizeStart[element.id].cols - i)) / (this.sizeStart[element.id].cols - i + 1);
                            
                            const leftElements = this.GetLeftElements(elementToShrink).sort((a, b) =>
                              a.ResponsiveProperties().x + a.ResponsiveProperties().cols > b.ResponsiveProperties().x + b.ResponsiveProperties().cols
                                ? 1
                                : -1,
                            );
                            
                            const isAnyOfChainedElementsForElementOnEdge =
                              this.workAreaService.GetChainedElementsWest(elementToShrink, 2).filter(e => e.ResponsiveProperties().x < 1).length > 0;
                            
                            if (isAnyOfChainedElementsForElementOnEdge || elementToShrinkPositioning.x <= 1) {
                              // =========setting cols for shrinked elments
                              
                              // console.log('new el cols', elementToShrinkPositioning.x + elementToShrinkPositioning.cols - colsDifference, this.sizeStart.cols - i);
                              
                              elementToShrinkPositioning.cols = Math.floor(elementToShrinkPositioning.cols - colsDifference);
                              // elementToShrinkPositioning.cols =
                              //   Math.ceil(
                              //     elementToShrinkPositioning.x +
                              //       elementToShrinkPositioning.cols -
                              //       colsDifference
                              //   ) >
                              //   this.sizeStart.cols - i
                              //     ? this.sizeStart.cols -
                              //       i -
                              //       elementToShrinkPositioning.x +
                              //       1
                              //     : Math.floor(
                              //         elementToShrinkPositioning.cols -
                              //           colsDifference
                              //       );
                              // ==========================================
                              
                              if (leftElements.length > 0) {
                                const overlapingme =
                                  leftElements[leftElements.length - 1].ResponsiveProperties().x +
                                  leftElements[leftElements.length - 1].ResponsiveProperties().cols >
                                  elementToShrinkPositioning.x + 1;
                                if (overlapingme) {
                                  const newX =
                                    (elementToShrinkPositioning.x * (this.sizeStart[element.id].cols - i)) /
                                    (this.sizeStart[element.id].cols - i + 1);
                                  // console.log('diff', newX);
                                  elementToShrinkPositioning.x = Math.round(newX);
                                } else {
                                  elementToShrinkPositioning.x = Math.floor(
                                    leftElements[leftElements.length - 1].ResponsiveProperties().x +
                                    leftElements[leftElements.length - 1].ResponsiveProperties().cols,
                                  );
                                }
                              } else {
                                elementToShrinkPositioning.x = 0;
                              }
                              
                              // if (startElement.Id === elementToShrink.Id) {
                              //   elementToShrinkPositioning.cols =
                              //     newWgCols - elementToShrinkPositioning.x;
                              // }
                            } else {
                              elementToShrinkPositioning.x = elementToShrinkPositioning.x - 1;
                            }
                            elementToShrinkPositioning.x = elementToShrinkPositioning.x < 0 ? 0 : elementToShrinkPositioning.x;
                            
                            if (elementsToSave.filter(ets => ets.Id === elementToShrink.Id).length === 0) {
                              elementsToSave.push(elementToShrink);
                            }
                          });
                        } else {
                          elementsChained.forEach(e => {
                            if (elementsToSave.filter(ets => ets.Id === e.Id).length === 0) {
                              elementsToSave.push(e);
                            }
                            
                            e.ResponsiveProperties().x = e.ResponsiveProperties().x - 1;
                          });
                          
                          // console.log('push chain');
                        }
                      });
                    }
                    
                    startElementsOnEdge.forEach((see: IRepresentativeMolecule) => {
                      if (see.ResponsiveProperties().x + see.ResponsiveProperties().cols + 1 >= newWgCols) {
                        see.ResponsiveProperties().cols = newWgCols - see.ResponsiveProperties().x;
                      }
                    });
                    
                    elementsToSave.forEach((e: IRepresentativeMolecule) => {
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'cols',
                          value: e.ResponsiveProperties().cols,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Size Changed`,
                          name: 'Width',
                        }),
                      );
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'x',
                          value: e.ResponsiveProperties().x,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Position Changed`,
                          name: 'Position - X',
                        }),
                      );
                    });
                  }
                } else {
                  this.ShrinkElementsHorizontally(this.busService.DirectChildrenElements(element.id), molecule, element.cols);
                }
                
                if (molecule.ResponsiveProperties().x !== this.sizeStart[element.id].x) {
                  propertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: element.id,
                      property: 'x',
                      value: molecule.ResponsiveProperties().x,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: `Size Changed`,
                      name: 'Position - X',
                    }),
                  );
                }
                
                molecule.ResponsiveProperties().colsQty = newWgCols;
                molecule.GridsterConfig.minCols = molecule.GridsterConfig.maxCols = newWgCols;
                molecule.GridsterConfig.maxItemCols = molecule.GridsterConfig.maxCols = newWgCols;
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'cols',
                    value: newWgCols,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Width',
                  }),
                );
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'colsQty',
                    value: molecule.ResponsiveProperties().colsQty,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'WG Width',
                  }),
                );
              }
              
              // address rows
              if (molecule.ResponsiveProperties().rows !== this.sizeStart[element.id].rows) {
                let newWgRows =
                  molecule.ResponsiveProperties().rows > this.sizeStart[element.id].rows
                    ? molecule.GridsterConfig.minRows + (molecule.ResponsiveProperties().rows - this.sizeStart[element.id].rows)
                    : molecule.GridsterConfig.minRows - (this.sizeStart[element.id].rows - molecule.ResponsiveProperties().rows);
                
                newWgRows = element.rows;
                
                if (!this.workAreaService.editorPreferences.resizeAll) {
                  const elementsToSave = [];
                  const rowsContracted = this.sizeStart[element.id].rows - newWgRows;
                  // console.log('cols contracted', rowsContracted);
                  
                  if (rowsContracted > 0) {
                    for (let i = 1; i <= rowsContracted; i++) {
                      const elementsResized = this.busService
                      .DirectChildrenElements(element.id)
                      .filter(e => e.ResponsiveProperties().y + e.ResponsiveProperties().rows > newWgRows)
                      .sort((a, b) =>
                        a.ResponsiveProperties().y + a.ResponsiveProperties().rows > b.ResponsiveProperties().y + b.ResponsiveProperties().rows
                          ? -1
                          : 1,
                      );
                      
                      // console.log('elements affected', elementsResized);
                      
                      const startElements = [];
                      elementsResized.forEach(e => {
                        const elementsToRight = [];
                        
                        elementsResized.forEach(eCompare => {
                          const xFinal = eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols;
                          const xStart = eCompare.ResponsiveProperties().x;
                          
                          if (
                            eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows >
                            e.ResponsiveProperties().y + e.ResponsiveProperties().rows &&
                            ((xFinal >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                              (xStart >= e.ResponsiveProperties().x && xStart <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                              (xStart <= e.ResponsiveProperties().x && xFinal >= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                              (xStart >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols))
                          ) {
                            elementsToRight.push(eCompare);
                          }
                        });
                        
                        if (elementsToRight.length === 0) {
                          startElements.push(e);
                        }
                      });
                      
                      // console.log('start elements', startElements);
                      
                      startElements.forEach(startElement => {
                        let elementsChained = [
                          ...new Set([startElement].concat(this.workAreaService.GetChainedElementsNorth(startElement, 1))),
                        ] as IRepresentativeMolecule[];
                        
                        // console.log('elements on chain', elementsChained);
                        
                        if (elementsChained.filter(e => e.ResponsiveProperties().y < 1).length > 0) {
                          // console.log('shrink chain');
                          
                          elementsChained = elementsChained.sort((a, b) => (a.ResponsiveProperties().y > b.ResponsiveProperties().y ? 1 : -1));
                          
                          elementsChained.forEach(elementToShrink => {
                            // console.log('=====================================================');
                            
                            const elementToShrinkPositioning = elementToShrink.ResponsiveProperties();
                            
                            const rowsDifference =
                              elementToShrinkPositioning.rows -
                              (elementToShrinkPositioning.rows * (this.sizeStart[element.id].rows - i)) / (this.sizeStart[element.id].rows - i + 1);
                            
                            const northElements = this.GetNorthElements(elementToShrink).sort((a, b) =>
                              a.ResponsiveProperties().y + a.ResponsiveProperties().rows > b.ResponsiveProperties().y + b.ResponsiveProperties().rows
                                ? 1
                                : -1,
                            );
                            
                            const isAnyOfChainedElementsForElementOnEdge =
                              this.workAreaService.GetChainedElementsNorth(elementToShrink, 2).filter(e => e.ResponsiveProperties().y < 1).length > 0;
                            
                            if (isAnyOfChainedElementsForElementOnEdge || elementToShrinkPositioning.y <= 1) {
                              // =========setting rows for shrinked elments
                              elementToShrinkPositioning.rows = Math.floor(elementToShrinkPositioning.rows - rowsDifference);
                              // ========================================== here
                              
                              if (northElements.length > 0) {
                                const overlapingme =
                                  northElements[northElements.length - 1].ResponsiveProperties().y +
                                  northElements[northElements.length - 1].ResponsiveProperties().rows >
                                  elementToShrinkPositioning.y + 1;
                                if (overlapingme) {
                                  const newY =
                                    (elementToShrinkPositioning.y * (this.sizeStart[element.id].rows - i)) /
                                    (this.sizeStart[element.id].rows - i + 1);
                                  // console.log('diff', newY);
                                  elementToShrinkPositioning.y = Math.round(newY);
                                } else {
                                  elementToShrinkPositioning.y = Math.floor(
                                    northElements[northElements.length - 1].ResponsiveProperties().y +
                                    northElements[northElements.length - 1].ResponsiveProperties().rows,
                                  );
                                }
                              } else {
                                elementToShrinkPositioning.y = 0;
                              }
                            } else {
                              elementToShrinkPositioning.y = elementToShrinkPositioning.y - 1;
                            }
                            elementToShrinkPositioning.y = elementToShrinkPositioning.y < 0 ? 0 : elementToShrinkPositioning.y;
                            
                            if (elementsToSave.filter(ets => ets.Id === elementToShrink.Id).length === 0) {
                              elementsToSave.push(elementToShrink);
                            }
                          });
                        } else {
                          elementsChained.forEach(e => {
                            if (elementsToSave.filter(ets => ets.Id === e.Id).length === 0) {
                              elementsToSave.push(e);
                            }
                            
                            e.ResponsiveProperties().y = e.ResponsiveProperties().y - 1;
                          });
                          
                          // console.log('push chain');
                        }
                      });
                    }
                    
                    elementsToSave.forEach((e: IRepresentativeMolecule) => {
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'rows',
                          value: e.ResponsiveProperties().rows,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Size Changed`,
                          name: 'Height',
                        }),
                      );
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'y',
                          value: e.ResponsiveProperties().y,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Position Changed`,
                          name: 'Position - Y',
                        }),
                      );
                    });
                  }
                } else {
                  this.ShrinkElementsVertically(this.busService.DirectChildrenElements(element.id), molecule, element.rows);
                }
                
                if (molecule.ResponsiveProperties().y !== this.sizeStart[element.id].y) {
                  propertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: element.id,
                      property: 'y',
                      value: molecule.ResponsiveProperties().y,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: `Size Changed`,
                      name: 'Position - Y',
                    }),
                  );
                }
                
                molecule.ResponsiveProperties().rowsQty = newWgRows - (molecule.Properties.offsetRows || 0);
                molecule.GridsterConfig.minRows = molecule.ResponsiveProperties().rowsQty;
                molecule.GridsterConfig.maxItemRows = molecule.GridsterConfig.maxRows = molecule.ResponsiveProperties().rowsQty;
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'rows',
                    value: newWgRows,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Height',
                  }),
                );
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'rowsQty',
                    value: molecule.ResponsiveProperties().rowsQty,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'WG Height',
                  }),
                );
              }
              
              molecule.GridsterConfig.api.optionsChanged();
              
              this.propertiesService.SaveProperties(propertiesToSave).subscribe();
              
              this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(molecule.Id);
            }
          }, 50);
        }
        
        setTimeout(() => {
          const propertiesToSave = [];
          let sizeChanged = true;
          let positionChanged = true;
          if (
            molecule.ResponsiveProperties().cols !== this.sizeStart[element.id].cols ||
            molecule.ResponsiveProperties().rows !== this.sizeStart[element.id].rows
          ) {
            // console.log(this.sizeStart);
            
            let newCols = element.cols;
            let newRows = element.rows;
            
            if (!this.context.RepresentativeMoleculeFitOnX(molecule.ResponsiveProperties().x, molecule.ResponsiveProperties().cols)) {
              newCols = this.context.ResponsiveProperties().cols - molecule.ResponsiveProperties().x;
              molecule.ResponsiveProperties().cols = element.cols = newCols;
            }
            
            if (!this.context.RepresentativeMoleculeFitOnY(molecule.ResponsiveProperties().y, molecule.ResponsiveProperties().rows)) {
              newRows = this.context.ResponsiveProperties().rows - molecule.ResponsiveProperties().y;
              molecule.ResponsiveProperties().rows = element.rows = newRows;
            }
            
            if (molecule.GridsterConfig) {
              molecule.GridsterConfig.api.optionsChanged();
            }
            this.context.GridsterConfig.api.optionsChanged();
            molecule.$Resized.emit();
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: element.id,
                property: 'cols',
                value: newCols,
                path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                change: `Size Changed`,
                name: 'Size - Cols',
              }),
              new PropertyVersioningDto({
                elementId: element.id,
                property: 'rows',
                value: newRows,
                path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                change: `Size Changed`,
                name: 'Size - Rows',
              }),
            );
            
            // if (molecule.StyleMetadata.styles.length > 0 && !molecule.StyleMetadata.manualAdjustedProperties.map(p => p.property).includes('cols')) {
            //   molecule.StyleMetadata.manualAdjustedProperties.push({ property: 'cols', path: `properties.responsive.${this.cobbleService.Cobble.deviceType}` });
            //   molecule.SavePropertyFromVersioning(new PropertyVersioningDto({
            //     elementId: molecule.Id.toString(),
            //     property: 'styleMetadata',
            //     value: molecule.StyleMetadata,
            //     path: '',
            //     change: 'Style edited',
            //     name: 'cols'
            //   })).subscribe();
            // }
            
            // if (molecule.StyleMetadata.styles.length > 0 && !molecule.StyleMetadata.manualAdjustedProperties.map(p => p.property).includes('rows')) {
            //   molecule.StyleMetadata.manualAdjustedProperties.push({ property: 'rows', path: `properties.responsive.${this.cobbleService.Cobble.deviceType}` });
            //   molecule.SavePropertyFromVersioning(new PropertyVersioningDto({
            //     elementId: molecule.Id.toString(),
            //     property: 'styleMetadata',
            //     value: molecule.StyleMetadata,
            //     path: '',
            //     change: 'Style edited',
            //     name: 'rows'
            //   })).subscribe();
            // }
          } else {
            sizeChanged = false;
          }
          
          if (
            molecule.ResponsiveProperties().x !== this.sizeStart[element.id].x ||
            molecule.ResponsiveProperties().y !== this.sizeStart[element.id].y
          ) {
            if (molecule.ResponsiveProperties().x !== this.sizeStart[element.id].x) {
              propertiesToSave.push(
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'x',
                  value: molecule.ResponsiveProperties().x,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Size Changed`,
                  name: 'Position - X',
                }),
              );
            }
            
            if (molecule.ResponsiveProperties().y !== this.sizeStart[element.id].y) {
              propertiesToSave.push(
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'y',
                  value: molecule.ResponsiveProperties().y,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Size Changed`,
                  name: 'Position - Y',
                }),
              );
            }
          } else {
            positionChanged = false;
          }
          
          if (!positionChanged && !sizeChanged) {
            this.ClickOnElement(molecule, event);
          }
          
          if (propertiesToSave.length > 0) {
            this.angularZone.runOutsideAngular(() => {
              propertiesToSave.forEach(pToSave => {
                if (
                  molecule.StyleMetadata.styles.length > 0 &&
                  !molecule.StyleMetadata.manualAdjustedProperties.find(p => p.path === pToSave.path && p.property === pToSave.property)
                ) {
                  molecule.StyleMetadata.manualAdjustedProperties.push({ property: pToSave.property, path: pToSave.path });
                  
                  molecule
                  .SavePropertyFromVersioning(
                    new PropertyVersioningDto({
                      elementId: molecule.Id.toString(),
                      property: 'styleMetadata',
                      value: molecule.StyleMetadata,
                      path: '',
                      change: 'Style edited',
                      name: pToSave.property.replace(/([a-z])([A-Z])/g, '$1 $2'),
                    }),
                  )
                  .subscribe();
                }
              });
              
              this.propertiesService.SaveProperties(propertiesToSave).subscribe();
              const positionxy = {
                x: molecule.ResponsiveProperties().x,
                y: molecule.ResponsiveProperties().y,
                cols: molecule.ResponsiveProperties().cols,
                rows: molecule.ResponsiveProperties().rows,
              };
              
              this.workAreaService.elementsSelectedOriginalPosition[molecule.Id] = positionxy;
            });
          }
        }, 50);
        
        this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
      },
      start: (element: any) => {
        // console.log('init drag', element);
        this.workAreaService.elementClicked = this.busService.Get(element.id);
        const siblings = this.busService.GetSiblings(element.id);
        const xEdges = siblings.map(s => {
          return [
            {
              id: s.Id,
              value: s.ResponsiveProperties().x,
              type: 'edge',
            },
            {
              id: s.Id,
              value: s.ResponsiveProperties().x + s.ResponsiveProperties().cols,
              type: 'edge',
            },
          ];
        });
        const yEdges = siblings.map(s => {
          return [
            {
              value: s.ResponsiveProperties().y,
              type: 'edge',
            },
            {
              value: s.ResponsiveProperties().y + s.ResponsiveProperties().rows,
              type: 'edge',
            },
          ];
        });
        
        this.sizeStart[element.id] = {
          coordinates: document.querySelector(`#gridsterItem-${ element.id } .coordinates-container`),
          eHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-e`),
          sHandle: document.querySelector(`#gridsterItem-${ element.id } .gridster-item-resizable-handler.handle-s`),
          preview: document.querySelector(`#gridsterItem-${ this.context.Id } .workgroup-gridster > gridster-preview`),
          cols: element.cols,
          rows: element.rows,
          element: this.workAreaService.elementClicked,
          x: element.x,
          y: element.y,
          xEdges: uniqBy(flatten(xEdges as any), 'value'),
          yEdges: uniqBy(flatten(yEdges as any), 'value'),
        };
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
      },
    },
    itemInitCallback: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
      // setTimeout(() => {
      //   this.UpdateChildContextMenuPosition(true);
      // }, 100);
    },
    swap: false,
    pushItems: this.workAreaService.editorPreferences.push,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: { north: true, east: true, south: true, west: true },
    pushResizeItems: false,
    displayGrid: DisplayGrid.Always,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false,
  };
  // endregion
  
  public moleculeComponents = {
    Button: ButtonMoleculeComponent,
    Badge: BadgeMoleculeComponent,
    Breadcrumb: BreadcrumbMoleculeComponent,
    WorkGroup: WorkgroupMoleculeComponent,
    Textbox: TextboxMoleculeComponent,
    Textarea: TextareaMoleculeComponent,
    Label: LabelMoleculeComponent,
    Datepicker: DatepickerMoleculeComponent,
    Dropdown: DropdownMoleculeComponent,
    H1: H1MoleculeComponent,
    H2: H2MoleculeComponent,
    H3: H3MoleculeComponent,
    H4: H4MoleculeComponent,
    H5: H5MoleculeComponent,
    Chart: ChartMoleculeComponent,
    Image: ImageMoleculeComponent,
    Iframe: IframeMoleculeComponent,
    Checkbox: CheckboxMoleculeComponent,
    Table: TableMoleculeComponent,
    Icon: IconMoleculeComponent,
    Radio: RadioMoleculeComponent,
    QrCode: QrCodeMoleculeComponent,
    Stepper: StepperMoleculeComponent,
    Slider: SliderMoleculeComponent,
    Custom: CustomMoleculeComponent,
    Progress: ProgressMoleculeComponent,
  };
  
  constructor(
    public busService: BusService,
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    private ref: ChangeDetectorRef,
    bottomSheet: MatBottomSheet,
    public propertiesService: ApiPropertiesService,
    public toolsService: ToolsService,
    public draggableWindowService: DraggableWindowService,
    private fileService: ApiFileService,
    public el: ElementRef<HTMLElement>,
    public communicationService: CommunicationService,
    changeDetectorRef: ChangeDetectorRef,
    private angularZone: NgZone,
  ) {
    super(bottomSheet, el, changeDetectorRef);
    
    // this.disableHover = true;
    this.debug = this.localStorageService.IsDebug();
  }
  
  ngOnInit() {
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    
    this.subscription = this.communicationService.Event.System.Update.$RefreshWorkgroups.subscribe(wgId => {
      console.log('=event=');
      this.RefreshUI();
      if (wgId) {
        if (wgId === this.context.Id) {
          this.UpdateWGBackground();
          this.busService.ChildrenElements(wgId).forEach(c => c.$Resized.emit());
          this.ref.markForCheck();
          this.ref.detectChanges();
        } else if (this.busService.IsMyChild(this.context.Id, wgId)) {
          // console.log('update wg');
          this.ref.markForCheck();
        }
      } else {
        this.busService.ChildrenElements(this.context.Id).forEach(c => {
          c.$Resized.emit();
        });
        
        this.UpdateWGBackground();
        this.ref.markForCheck();
      }
      setTimeout(() => {
        if (this.context.GridsterConfig.api) {
          this.context.GridsterConfig.api.optionsChanged();
        }
      }, 700);
    });
    
    if (!this.cobbleService.Cobble.running || this.cobbleService.Cobble.preview) {
      this.subscription.add(
        this.communicationService.Event.System.Update.$ChangesOnMolecules.subscribe((molecule: IRepresentativeMolecule) => {
          console.log('=event='); // console.log('$ChangesOnMolecules');
          this.ref.markForCheck();
          const lockedView = this.cobbleService.Cobble.properties.views.find(v => v.locked);
          
          if (
            !this.workAreaService.actualEditorViews.filter(v => v !== undefined).map(v => v.id).includes(this.context.Properties.view) &&
            !(lockedView && lockedView.id === this.context.Properties.view)
          ) {
            // console.log('molecules change vent return');
            // console.log('return', molecule);
            return;
          }
          
          if (molecule) {
            if (molecule.Id === this.context.Id || this.busService.IsMyChild(this.context.Id, molecule.Id)) {
              // console.log('molecules change event', molecule);
              this.Throttle(
                (func, delay, context) => {
                  this.refreshChildren();
                  
                  this.ref.markForCheck();
                  if (this.context.GridsterConfig.api) {
                    this.context.GridsterConfig.api.optionsChanged();
                  }
                  this.context.RefreshUI();
                  if (this.ref && !(this.ref as ViewRef).destroyed) {
                    this.ref.detectChanges();
                  }
                },
                50,
                this,
                null,
              );
            }
          } else {
            // console.log(2);
            
            this.Throttle(
              (func, delay, context) => {
                this.refreshChildren();
                setTimeout(() => {
                  if (this.context.GridsterConfig.api) {
                    this.context.GridsterConfig.api.optionsChanged();
                  }
                  this.communicationService.Event.System.App.$RefreshUI.emit(true);
                  this.ref.markForCheck();
                }, 300);
              },
              200,
              this,
              null,
            );
          }
        }),
      );
      
      this.subscription.add(
        this.communicationService.Event.System.Update.$ActivateWorkgroupDetection.subscribe(wgId => {
          console.log('=event=');
          if (wgId) {
            if (wgId === this.context.Id || this.busService.IsMyChild(this.context.Id, wgId)) {
              // console.log('attaching detection');
              
              this.ref.reattach();
              this.ref.detectChanges();
            }
          }
        }),
      );
      
      this.subscription.add(
        this.communicationService.Event.Editor.$RetryCaptureCanvas.subscribe(condition => {
          this.SetBackgroundImageAsUrlTemporarily();
        }),
      );
      
      this.subscription.add(
        this.communicationService.Event.System.Update.$DeactivateWorkgroupDetection.subscribe(wgId => {
          console.log('=event='); // console.log('dettaching wg');
          setTimeout(() => {
            this.ref.detach();
          }, 300);
        }),
      );
      
      this.subscription.add(
        this.communicationService.Event.Editor.Preferences.$PreferenceChange.subscribe(preference => {
          console.log('=event='); // console.log('dettaching wg');
          if (preference === 'gridline') {
            this.CalcGrid();
          }
        }),
      );
      
      this.subscription.add(
        this.communicationService.Event.Editor.WorkArea.$AddRepMolecule.subscribe(data => {
          console.log('=event='); // console.log('dettaching wg');
          if (this.context.Id === data.parent.Id) {
            this.OnDrop(data.moleculeTemplate, data.event, data.position, data.properties);
          }
        }),
      );
      
      this.subscription.add(
        this.communicationService.Event.Editor.Preferences.$PreferenceChange.subscribe(preference => {
          console.log('=event='); // console.log('dettaching wg');
          if (preference === 'customGuideline') {
            this.context.DisplayCustomGuidelines = true;
            this.CalcRuler();
          }
        }),
      );
      
      this.subscription.add(
        this.communicationService.Event.Editor.$RefreshCustomGuidelines.subscribe(repMoleculeId => {
          console.log('=event='); // console.log('dettaching wg');
          if (repMoleculeId === this.context.Id) {
            this.CalcRuler();
            this.CalcGrid();
          }
        }),
      );
    } else {
    }
    
    this.UpdateWGBackground();
    
    const gridsterConfig = this.cobbleService.Cobble.running ? this.runningGridsterConfig : this.editorGridsterConfig;
    
    // initializing with wg values
    gridsterConfig.minCols = this.context.ResponsiveProperties().colsQty;
    gridsterConfig.maxCols = this.context.ResponsiveProperties().colsQty;
    gridsterConfig.minRows = this.context.ResponsiveProperties().rowsQty;
    gridsterConfig.maxRows = this.context.ResponsiveProperties().rowsQty;
    gridsterConfig.maxItemCols = this.context.ResponsiveProperties().colsQty;
    gridsterConfig.maxItemRows = this.context.ResponsiveProperties().rowsQty;
    //
    
    this.context.GridsterConfig = gridsterConfig;
    
    this.showAltText = this.context.Properties.showAltText;
    setTimeout(() => {
      this.refreshChildren();
      this.FireInitEvents();
      
      this.CalcGrid();
      this.CalcRuler();
    }, 50);
  }
  
  CalcRuler() {
    if (!this.workAreaService.editorPreferences.customGuideline) {
      this.ruleNumbersX = [];
      this.ruleNumbersY = [];
      this.context.DisplayCustomGuidelines = false;
    }
    
    this.ruleNumbersX = [];
    this.ruleNumbersY = [];
    const gridsterEl = document.querySelector(`#gridsterItem-${ this.context.Id } .workgroup-gridster`) as any;
    
    if (gridsterEl) {
      const curWidth = gridsterEl.offsetWidth;
      const curHeight = gridsterEl.offsetHeight;
      this.rulerCurColWidth = curWidth === 0 ? 4 : (curWidth / this.context.ResponsiveProperties().cols) * 1.5;
      this.rulerCurRowHeight = curHeight === 0 ? 4 : (curHeight / this.context.ResponsiveProperties().rows) * 1.5;
      const columns = [];
      const rows = [];
      
      for (let position = 0; position < this.context.ResponsiveProperties().cols; position++) {
        columns.push(Math.round(position * this.rulerCurColWidth));
      }
      
      for (let position = 0; position < Math.ceil(this.context.ResponsiveProperties().cols / (this.rulerCurColWidth * 1.5)); position++) {
        const x = Math.round(this.rulerCurColWidth * position);
        
        this.ruleNumbersX.push({
          value: Math.round((columns[x] / this.rulerCurColWidth) * 1.5),
          left: columns[x] - 3,
        });
      }
      
      for (let position = 0; position < this.context.ResponsiveProperties().rows; position++) {
        rows.push(Math.round(position * this.rulerCurRowHeight));
      }
      
      for (let position = 0; position < Math.ceil(this.context.ResponsiveProperties().rows / (this.rulerCurRowHeight * 1.5)); position++) {
        const y = Math.round(this.rulerCurRowHeight * position);
        
        this.ruleNumbersY.push({
          value: Math.round((rows[y] / this.rulerCurRowHeight) * 1.5),
          top: columns[y] - 3,
        });
      }
    }
    
    this.RefreshCustomGuidelines();
  }
  
  CalcGrid() {
    this.gridCols = [];
    this.gridRows = [];
    
    if (!this.workAreaService.editorPreferences.gridline) {
      this.displayGrid = false;
      return;
    }
    
    this.gridSize = 5;
    
    const gridsterEl = document.querySelector(`#gridsterItem-${ this.context.Id } .workgroup-gridster`) as any;
    const curWidth = gridsterEl.offsetWidth;
    const curHeight = gridsterEl.offsetHeight;
    const curColWidth = curWidth / this.context.ResponsiveProperties().cols;
    const curColHeight = curHeight / this.context.ResponsiveProperties().rows;
    const columns = [];
    const rows = [];
    
    for (let position = 0; position < this.context.ResponsiveProperties().cols; position++) {
      columns.push(Math.round(position * curColWidth));
    }
    
    for (let position = 0; position < Math.ceil(this.context.ResponsiveProperties().cols / curColWidth); position++) {
      const x = Math.round(curColWidth * position);
      this.gridCols.push({
        id: 0,
        y: 0,
        x: columns[x],
        value: Math.round(columns[x] / curColWidth),
        type: 'grid',
      });
    }
    
    for (let position = 0; position < this.context.ResponsiveProperties().rows; position++) {
      rows.push(Math.round(position * curColHeight));
    }
    
    for (let position = 0; position < this.context.ResponsiveProperties().rows; position++) {
      const y = Math.round(curColHeight * position);
      this.gridRows.push({
        id: 0,
        y: rows[y],
        x: 0,
        value: Math.round(rows[y] / curColHeight),
        type: 'grid',
      });
    }
    
    // old method
    // const cols = Math.floor(this.context.ResponsiveProperties().cols / this.gridSize);
    // const rows = Math.floor(this.context.ResponsiveProperties().rows / this.gridSize);
    // const cols = 500;
    // const rows = 500;
    
    // const xk = 1.5 / this.context.ResponsiveProperties().cols;
    // const yk = 1.5 / this.context.ResponsiveProperties().rows;
    // for (let position = 1; position <= rows; position++) {
    //   const y = (position * this.gridSize);
    //   this.gridRows.push({
    //     id: 0,
    //     y: (y * 4) - this.Math.floor((y * 4) * yk),
    //     x: 0,
    //     value: y,
    //     type: 'grid'
    //   });
    // }
    
    this.displayGrid = true;
  }
  
  ActivateMagnetElements() {
    // region MAGNET
    const magnet = new Magnet(document.querySelectorAll('.workgroup-child'));
    console.log('magnet', magnet);
    // endregion
  }
  
  SetBackgroundImageAsUrlTemporarily() {
    if (this.context.Properties.background.backgroundTypeImage) {
      const imgSource = this.ImgSource;
      
      if (
        this.context.Properties.background.backgroundImageUrl.indexOf('http') > -1 ||
        this.context.Properties.background.backgroundImageUrl.indexOf('https') > -1
      ) {
        this.ImgSource = 'url(' + this.context.Properties.background.backgroundImageUrl + ')';
      } else {
        this.ImgSource = `url('${ Constants.Environment.apiUrl }files/view/?fileName=${ this.context.Properties.background.backgroundImageUrl }')`;
      }
      this.ref.markForCheck();
      
      setTimeout(() => {
        this.ImgSource = imgSource;
      }, 2000);
    }
  }
  
  UpdateWGBackground() {
    this.ImgSource = 'url(' + this.context.Properties.background.backgroundImageUrl + ')';
    
    // console.log(this.ImgSource);
    
    if (this.context.Properties.background.backgroundTypeImage) {
      if (
        this.context.Properties.background.backgroundImageUrl.indexOf('http') > -1 ||
        this.context.Properties.background.backgroundImageUrl.indexOf('https') > -1
      ) {
        this.ImgSource = 'url(' + this.context.Properties.background.backgroundImageUrl + ')';
        this.ref.markForCheck();
      } else {
        try {
          this.fileService.getFileInformation(this.context.Properties.background.backgroundImageUrl).subscribe(
            result => {
              if (result && result.contentType.indexOf('image') > -1) {
                this.ImgSource = 'url(' + 'data:image/png;base64,' + result.dataFile + ')';
              }
              this.ref.markForCheck();
            },
            error => {
            },
          );
        } catch (error) {
          this.ImgSource = '/assets/images/no_image.png';
        }
      }
    }
  }
  
  GetLeftElements(e: IRepresentativeMolecule) {
    const elementsFound = [];
    this.busService.DirectChildrenElements(e.ParentId).forEach(eCompare => {
      const yFinal = eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows;
      const yStart = eCompare.ResponsiveProperties().y;
      
      if (
        ((yFinal >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart >= e.ResponsiveProperties().y && yStart <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart <= e.ResponsiveProperties().y && yFinal >= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows)) &&
        eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols &&
        eCompare.Id !== e.Id
      ) {
        // console.log('Elements found', eCompare);
        elementsFound.push(eCompare);
      }
    });
    
    return elementsFound;
  }
  
  GetNorthElements(e: IRepresentativeMolecule) {
    const elementsFound = [];
    this.busService.DirectChildrenElements(e.ParentId).forEach(eCompare => {
      const xFinal = eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols;
      const xStart = eCompare.ResponsiveProperties().x;
      
      if (
        ((xFinal >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart >= e.ResponsiveProperties().x && xStart <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart <= e.ResponsiveProperties().x && xFinal >= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols)) &&
        eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows &&
        eCompare.Id !== e.Id
      ) {
        // console.log('Elements found', eCompare);
        elementsFound.push(eCompare);
      }
    });
    
    return elementsFound;
  }
  
  ShrinkElementsHorizontally(elements: IRepresentativeMolecule[], parent: IRepresentativeMolecule, parentCols: number) {
    const propertiesToSave = [];
    
    elements.forEach(child => {
      let gridRatio = 1;
      
      if (this.sizeStart[parent.Id].cols !== parent.ResponsiveProperties().colsQty) {
        // console.log('wg cols b', this.sizeStart.cols);
        // console.log('wg cols qty b', parent.ResponsiveProperties().colsQty);
        
        gridRatio = this.sizeStart[parent.Id].cols / parent.ResponsiveProperties().colsQty;
      }
      
      // console.log('grid ratio', gridRatio);
      
      let ratioColsChange = ((child.ResponsiveProperties().cols * parentCols) / this.sizeStart[parent.Id].cols) * gridRatio;
      let ratioXChange = ((child.ResponsiveProperties().x * parentCols) / this.sizeStart[parent.Id].cols) * gridRatio;
      // console.log('ratio cols change', ratioColsChange);
      // console.log('ratio x change', ratioXChange);
      
      if (Math.round(ratioColsChange) + Math.round(ratioXChange) > parentCols) {
        ratioColsChange = Math.floor(ratioColsChange);
        ratioXChange = Math.floor(ratioXChange);
      } else {
        ratioColsChange = Math.round(ratioColsChange);
        ratioXChange = Math.round(ratioXChange);
      }
      
      ratioColsChange = ratioColsChange < 1 ? 1 : ratioColsChange;
      ratioXChange = ratioXChange < 0 ? 0 : ratioXChange;
      
      child.ResponsiveProperties().cols = ratioColsChange;
      child.ResponsiveProperties().x = ratioXChange;
      
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'x',
          value: child.ResponsiveProperties().x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Position Changed`,
          name: 'Position - X',
        }),
      );
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'cols',
          value: child.ResponsiveProperties().cols,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Size Changed`,
          name: 'Width',
        }),
      );
    });
    
    this.propertiesService.SaveProperties(propertiesToSave).subscribe();
  }
  
  ShrinkElementsVertically(elements: IRepresentativeMolecule[], parent: IRepresentativeMolecule, parentRows: number) {
    const propertiesToSave = [];
    
    elements.forEach(child => {
      let gridRatio = 1;
      
      if (this.sizeStart[parent.Id].rows !== parent.ResponsiveProperties().rowsQty) {
        // console.log('wg cols b', this.sizeStart.rows);
        // console.log('wg cols qty b', parent.ResponsiveProperties().rowsQty);
        
        gridRatio = this.sizeStart[parent.Id].rows / parent.ResponsiveProperties().rowsQty;
      }
      
      // console.log('grid ratio', gridRatio);
      
      let ratioRowsChange = ((child.ResponsiveProperties().rows * parentRows) / this.sizeStart[parent.Id].rows) * gridRatio;
      let ratioYChange = ((child.ResponsiveProperties().y * parentRows) / this.sizeStart[parent.Id].rows) * gridRatio;
      
      if (Math.round(ratioRowsChange) + Math.round(ratioYChange) > parentRows) {
        ratioRowsChange = Math.floor(ratioRowsChange);
        ratioYChange = Math.floor(ratioYChange);
      } else {
        ratioRowsChange = Math.round(ratioRowsChange);
        ratioYChange = Math.round(ratioYChange);
      }
      
      ratioRowsChange = ratioRowsChange < 1 ? 1 : ratioRowsChange;
      ratioYChange = ratioYChange < 0 ? 0 : ratioYChange;
      
      child.ResponsiveProperties().rows = ratioRowsChange;
      child.ResponsiveProperties().y = ratioYChange;
      
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'y',
          value: child.ResponsiveProperties().y,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Position Changed`,
          name: 'Position - Y',
        }),
      );
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'rows',
          value: child.ResponsiveProperties().rows,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Size Changed`,
          name: 'Height',
        }),
      );
    });
    
    this.propertiesService.SaveProperties(propertiesToSave).subscribe();
  }
  
  public UpdateWGContextMenuPosition(moleculeId: number, init = false) {
    setTimeout(
      () => {
        const molecule = this.busService.Get(moleculeId.toString());
        const menuColsSize = 8.5;
        if (molecule.GridsterItem) {
          const leftRegex = /(\d+)px,\s/;
          const left = +leftRegex.exec(molecule.GridsterItem.style.transform)[1];
          const topRegex = /,\s(\d+)px,\s/g;
          const top = +topRegex.exec(molecule.GridsterItem.style.transform)[1];
          
          const k = this.context.ResponsiveProperties().colsQty / this.context.ResponsiveProperties().cols;
          
          const spaceAvailable = (this.context.ResponsiveProperties().colsQty - molecule.ResponsiveProperties().x) / k;
          
          const isMenuSpaceAvailable = spaceAvailable >= menuColsSize;
          
          molecule.ContextMenu.style.transform =
            molecule.ResponsiveProperties().y === 0 || molecule.ResponsiveProperties().y === 1
              ? molecule.GridsterItem.style.transform.replace(`, ${ top }px,`, `, ${ top + 15 }px,`)
              : molecule.GridsterItem.style.transform;
          
          molecule.ContextMenu.style.transform = isMenuSpaceAvailable
            ? molecule.ContextMenu.style.transform
            : molecule.ContextMenu.style.transform.replace(`(${ left }px,`, `(${ left - (menuColsSize - spaceAvailable) * 30 }px,`);
        }
      },
      init ? 300 : 0,
    );
  }
  
  public TrackById(index, item) {
    if (!item) {
      return null;
    }
    return item.Id;
  }
  
  workgroupDrag(event: any, condition: boolean) {
    this.busService.DirectChildrenElements(this.context.Id).forEach(child => {
      child.DragOver = false;
    });
  }
  
  ShowChangesLost() {
    this.workAreaService.ShowUnsavedDataWindow();
  }
  
  ClickOnElement(element: IRepresentativeMolecule, e: MouseEvent) {
    // console.log('click on element');
    e.preventDefault();
    e.stopPropagation();
    if (!this.cobbleService.Cobble.running) {
      if (this.workAreaService.tabManagement.settingTab) {
        this.workAreaService.SetTabOrder(element);
      } else {
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        this.workAreaService.ShowElementFocusedMenu(element, e);
      }
    }
  }
  
  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  RepresentativeElementClick(event: MouseEvent, repMolecule: IRepresentativeMolecule) {
    this.communicationService.Event.System.Dev.$RepMoleculeSelected.emit(repMolecule);
  }
  
  DataDroppedOnChildren($event: any, repMolecule: IRepresentativeMolecule) {
    console.log('drop on placeholder', repMolecule);
    this.communicationService.Event.Editor.$DropOnRepresentativeMolecule.emit({ event: $event, repMoleculeId: repMolecule.Id });
  }
  
  OpenProcessPanelForRepresentativeMolecule(repMolecule, event) {
    if (this.cobbleService.Cobble.running) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  }
  
  ClearDropZone(repMolecule: IRepresentativeMolecule, event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    repMolecule.CreationFromTemplate = false;
    repMolecule.SaveProperty('creationFromTemplate', 'Dropzone removed').subscribe();
    setTimeout(() => {
      this.workAreaService.HideElementFocusedMenu();
      setTimeout(() => {
        this.workAreaService.HideElementFocusedMenu();
      }, 50);
    }, 10);
  }
  
  MouseRelease(event: MouseEvent): void {
    console.log(event);
    if (this.context.RunningMode) {
      return;
    }
    console.log('wg receive');
    
    setTimeout(() => {
      let wgFit = null;
      
      if (this.dragService.dragBetweenWG.repMoleculeIdDragged === this.context.Id) {
        const viewWgs = this.busService.GetViewWorkgroupsAndStepperMolecules(this.cobbleService.Cobble, this.workAreaService.ActualView.id);
        
        const wgsFit = viewWgs.filter(
          w =>
            w.Id !== this.context.Id &&
            w.ParentId === this.cobbleService.Cobble.id &&
            w.ResponsiveProperties().x <= this.context.ResponsiveProperties().x &&
            w.ResponsiveProperties().y <= this.context.ResponsiveProperties().y &&
            w.ResponsiveProperties().x + w.ResponsiveProperties().cols >=
            this.context.ResponsiveProperties().x + this.context.ResponsiveProperties().cols &&
            w.ResponsiveProperties().y + w.ResponsiveProperties().rows >=
            this.context.ResponsiveProperties().y + this.context.ResponsiveProperties().rows,
        );
        
        if (wgsFit) {
          let smallestAreaWg: IRepresentativeMolecule = null;
          
          wgsFit.forEach(w => {
            if (smallestAreaWg) {
              if (
                smallestAreaWg.ResponsiveProperties().cols * smallestAreaWg.ResponsiveProperties().rows >
                w.ResponsiveProperties().cols * w.ResponsiveProperties().rows
              ) {
                smallestAreaWg = w;
              }
            } else {
              smallestAreaWg = w;
            }
          });
          
          wgFit = smallestAreaWg;
        }
      }
      
      if (this.dragService.dragBetweenWG.dragging) {
        if (wgFit && this.context.ParentId !== this.cobbleService.Cobble.id) {
          return;
        }
        
        this.dragService.dragBetweenWG.processing = true;
        this.dragService.dragBetweenWG.targetWgId = wgFit ? wgFit.Id : this.context.Id;
        
        const draggedMoleculeOldPosition = {
          x: this.workAreaService.dragStartPositions[this.dragService.dragBetweenWG.repMoleculeIdDragged].x,
          y: this.workAreaService.dragStartPositions[this.dragService.dragBetweenWG.repMoleculeIdDragged].y,
        };
        
        const draggedMoleculeNewPosition = {
          x: Math.floor(event.offsetX / 4),
          y: Math.floor(event.offsetY / 4),
        };
        
        this.dragService.dragBetweenWG.repMoleculeIdsDragged.forEach(id => {
          let position = null;
          
          if (wgFit) {
            position = {
              x: 1,
              y: 1,
            };
          } else if (id !== this.dragService.dragBetweenWG.repMoleculeIdDragged) {
            const deltaX = draggedMoleculeOldPosition.x - this.workAreaService.dragStartPositions[id].x;
            const deltaY = draggedMoleculeOldPosition.y - this.workAreaService.dragStartPositions[id].y;
            
            position = {
              x: draggedMoleculeNewPosition.x - deltaX,
              y: draggedMoleculeNewPosition.y - deltaY,
            };
          }
          
          this.dragService.dragBetweenWG.repMoleculeIdDragged = id;
          this.dragService.ProcessRepMoleculeDragBetweenWG(event, position);
        });
        
        // if (this.dragService.dragBetweenWG.repMoleculeIdsDragged.length > 0) {
        //
        // } else {
        //   this.dragService.ProcessRepMoleculeDragBetweenWG(event, wgFit ? { x: 1, y: 1 } : null);
        // }
      }
    }, 100);
    
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
  }
  
  MouseEnter(event: MouseEvent): void {
    this.mouseIn = true;
    if (this.dragService.dragBetweenWG.dragging && this.dragService.dragBetweenWG.sourceWgId === this.context.Id) {
      this.dragService.dragBetweenWG.showPreview = false;
    }
  }
  
  MouseLeave(event: MouseEvent): void {
    console.log('leave');
    this.mouseIn = false;
    if (this.dragService.dragBetweenWG.dragging) {
      this.dragService.dragBetweenWG.showPreview = true;
    }
  }
  
  AddCustomGuideline(event: MouseEvent, orientation: 'x' | 'y') {
    let position = 0;
    if (orientation === 'x') {
      const left = event.offsetX;
      position = Math.round(left / (this.rulerCurColWidth / 1.5));
    } else {
      const top = event.offsetY;
      position = Math.round(top / (this.rulerCurRowHeight / 1.5));
    }
    console.log(position);
    
    this.context.AddCustomGuideline(position, orientation);
    this.RefreshCustomGuidelines();
  }
  
  RemoveCustomGuideline(guideline: any, orientation: 'x' | 'y') {
    this.context.RemoveCustomGuideline(guideline, orientation);
    this.RefreshCustomGuidelines();
  }
  
  RefreshCustomGuidelines() {
    if (!this.workAreaService.editorPreferences.customGuideline) {
      return;
    }
    
    this.customGuidelinesX = [];
    this.customGuidelinesY = [];
    
    const gridsterEl = document.querySelector(`#gridsterItem-${ this.context.Id } .workgroup-gridster`) as any;
    const curWidth = gridsterEl.offsetWidth;
    const curHeight = gridsterEl.offsetHeight;
    const curColWidth = curWidth / this.context.ResponsiveProperties().cols;
    const curRowHeight = curHeight / this.context.ResponsiveProperties().rows;
    
    this.context.ResponsiveProperties().customGuidelines.x.forEach(col => {
      this.customGuidelinesX.push({
        id: 0,
        y: 0,
        x: col.position * curColWidth + 1,
        value: col.position,
        type: 'grid',
      });
    });
    
    this.context.ResponsiveProperties().customGuidelines.y.forEach(row => {
      this.customGuidelinesY.push({
        id: 0,
        y: row.position * curRowHeight,
        x: 0,
        value: row.position,
        type: 'grid',
      });
    });
  }
  
  LogRepMolecule(repMolecule: any, event: any) {
    console.log(repMolecule);
    
    this.workAreaService.primaryElementsSelected = [repMolecule];
    this.workAreaService.elementsSelected = [repMolecule];
    this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.emit(300);
    event.preventDefault();
    event.stopPropagation();
    this.devToolsService.OpenDevToolsForBus(repMolecule);
    
  }
  
  private ChildrenUpdate() {
    if (this.cobbleService.Cobble.running) {
      setTimeout(() => {
        this.children = this.GetWorkgroupsDirectChildren();
        
        this.communicationService.Event.System.Update.$ChangesOnCharts.emit(null);
        setTimeout(() => {
          this.toolsService.TextFit();
          setTimeout(() => {
            this.toolsService.TextFit();
          }, 200);
          this.ref.markForCheck();
        }, 100);
      }, 100);
    } else {
      this.children = this.GetWorkgroupsDirectChildren();
      console.log('children');
      this.communicationService.Event.System.Update.$ChangesOnCharts.emit();
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.markForCheck();
        this.ref.detectChanges();
      }
      this.communicationService.Event.System.App.$RefreshUI.emit(true);
      if (this.context.GridsterConfig.api) {
        this.context.GridsterConfig.api.optionsChanged();
      }
      
      // setTimeout(() => {
      //   // this.ActivateMagnetElements();
      // }, 2000);
    }
  }
  
  private GetWorkgroupsDirectChildren() {
    const children = this.busService.DirectChildrenElements(this.context.Id);
    children.forEach(child => {
      child.ResponsiveProperties().cols = child.ResponsiveProperties().x + child.ResponsiveProperties().cols > this.context.ResponsiveProperties().cols ?
        this.context.ResponsiveProperties().cols - child.ResponsiveProperties().x : child.ResponsiveProperties().cols;
      
      child.ResponsiveProperties().rows = child.ResponsiveProperties().y + child.ResponsiveProperties().rows > this.context.ResponsiveProperties().rows ?
        this.context.ResponsiveProperties().rows - child.ResponsiveProperties().y : child.ResponsiveProperties().rows;
    });
    
    children.sort((repMol1, repMol2) => (repMol1.Properties.tabindex < repMol2.Properties.tabindex) ? -1 :
      (repMol1.Properties.tabindex > repMol2.Properties.tabindex) ? 0 : 1);
    
    return children;
  }
  
  private InitChildren() {
    this.ChildrenUpdate();
    
    setTimeout(() => {
      this.context.GridsterConfig.draggable.enabled = this.editorGridsterConfig.draggable.enabled;
      this.context.GridsterConfig.draggable.stop = this.editorGridsterConfig.draggable.stop;
      this.context.GridsterConfig.draggable.start = this.editorGridsterConfig.draggable.start;
      this.context.GridsterConfig.resizable = this.editorGridsterConfig.resizable;
      this.context.GridsterConfig.dragStartCallback = this.editorGridsterConfig.dragStartCallback;
      this.context.GridsterConfig.enableEmptyCellClick = this.editorGridsterConfig.enableEmptyCellClick;
      this.context.GridsterConfig.enableEmptyCellDrop = this.editorGridsterConfig.enableEmptyCellDrop;
      this.context.GridsterConfig.enableEmptyCellDrag = this.editorGridsterConfig.enableEmptyCellDrag;
      this.context.GridsterConfig.pushItems = this.editorGridsterConfig.pushItems;
      this.context.GridsterConfig.pushDirections = this.editorGridsterConfig.pushDirections;
      this.context.GridsterConfig.itemChangeCallback = this.editorGridsterConfig.itemChangeCallback;
      this.context.GridsterConfig.emptyCellClickCallback = this.editorGridsterConfig.emptyCellClickCallback;
      this.context.GridsterConfig.emptyCellDropCallback = this.editorGridsterConfig.emptyCellDropCallback;
      this.context.GridsterConfig.itemInitCallback = this.editorGridsterConfig.itemInitCallback;
      
      setTimeout(() => {
        this.context.GridsterConfig.api.optionsChanged();
      }, 300);
    }, 2000);
  }
  
  private refreshChildren() {
    console.log('refresh wg children', this.children);
    
    // this.context.GridsterConfig.draggable.enabled = false;
    // this.context.GridsterConfig.resizable.enabled = false;
    this.ChildrenUpdate();
    
    // if (!this.cobbleService.Cobble.running && this.context.GridsterConfig && this.context.GridsterConfig.api) {
    //   setTimeout(() => {
    //     this.context.GridsterConfig.draggable.enabled = true;
    //     this.context.GridsterConfig.resizable.enabled = true;
    //   }, 1000);
    // }
  }
}
