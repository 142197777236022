import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeService } from 'app/running-area/services/runtime.service';
import { cloneDeep } from 'lodash-es';
import { debounceTime, map } from 'rxjs/operators';
import { LeapXLEventType } from '../../../shared/enums/leapxl-event-type.enum';
import { ParticleType } from '../../../shared/enums/particle-type.enum';
import { RepresentativeMoleculesType } from '../../../shared/enums/representative-molecules-types.enum';
import { LeapProcessMetadata } from '../../../shared/interfaces/leap-process-metadata.interface';
import { ActionMolecule } from '../../../shared/representative-molecule/interfaces/action-molecules';
import { Bus } from '../../../shared/representative-molecule/interfaces/bus';
import { DataElement } from '../../../shared/representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../../../shared/representative-molecule/interfaces/leapxl-event';
import { Particle } from '../../../shared/representative-molecule/interfaces/particle';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { ErrorMessengerService } from '../../../shared/services/error-messenger.service';
import { SnackerService } from '../../../shared/services/snacker.service';
import { ApiAuthService } from '../../services/api-auth.service';
import { ApiDataSourcesService } from '../../services/api-data-sources.service';
import { ApiFileService } from '../../services/api-file.service';
import { CacheService } from '../../services/cache.service';
import { ClientStorageService } from '../../services/client-storage.service';
import { DevToolsService } from '../../services/dev-tools.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { TemplateService } from '../../services/template.service';
import { ToolsService } from '../../services/tools.service';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';
import * as ActionMoleculesInstances from '../molecules';
import { Receptor } from '../receptors.enum';
import { BusService } from './bus.service';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root',
})
export class ProcessorService {
  slowdown = false;
  inDebounce = null;
  debug = false;
  inputstProcessed = [];
  private eventCache = {};
  private eventsBuffer = {};
  
  constructor(
    private http: HttpClient,
    private errorMessengerService: ErrorMessengerService,
    private cacheService: CacheService,
    private toolsService: ToolsService,
    private busService: BusService,
    private cobbleService: CobbleService,
    private eventsService: EventsService,
    private datasourceService: ApiDataSourcesService,
    private authService: ApiAuthService,
    private communicationService: CommunicationService,
    private templateService: TemplateService,
    private fileService: ApiFileService,
    private snackerService: SnackerService,
    private clientStorageService: ClientStorageService,
    private localStorageService: LocalStorageService,
    private runtimeService: RuntimeService,
    private devToolsService: DevToolsService,
  ) {
    this.debug = this.localStorageService.IsDebug();
    this.communicationService.Event.System.Auth.$AADUserLogged.subscribe(logged => {
      console.log('=event='); // console.log('$AADUserLogged');
      this.Resume();
    });
    
    this.communicationService.Event.Editor.$ActionMoleculeAdded.subscribe(
      (data: { actionMolecule: ActionMolecule; repMolecule: IRepresentativeMolecule; data?: any }) => {
        console.log('=event=');
        this.GetActionMoleculeFunctionInstance(data.actionMolecule).AfterAdded(data.repMolecule);
      },
    );
    
    this.communicationService.Event.Runtime.MolecularEngine.$ParticleProcessCompleted.subscribe(
      (particleProcessed: { key: string; particleId: string; eventKey: string; event: string; elementId: any; data: any; endProccess: boolean }) => {
        console.log('=event=');
        console.log(' === particleProcessed', particleProcessed);
        if (particleProcessed) {
        } else {
          return;
        }
        
        if (particleProcessed.key !== '') {
          // console.log(`particle processed: `, particleProcessed);
          // console.log(`particles channel: `, this.busService.ProcessorChannel);
          const repMolecule = this.busService.Get(particleProcessed.elementId);
          const particlesChannel = this.busService.GetBusToRunFromProcessorChannel(particleProcessed.key);
          
          if (particleProcessed.endProccess) {
            this.StopProcessor(particlesChannel, repMolecule, particleProcessed.key, particleProcessed.data);
          } else {
            // console.log('process key', particleProcessed.key);
            // console.log(particlesChannel);
            
            if (particlesChannel) {
              const index = particlesChannel.bus.Particles.findIndex(m => m.ParticleId === particleProcessed.particleId);
              
              particlesChannel.bus.Particles.splice(index < 0 ? 0 : index, 1);
              
              let timeout = 0;
              
              console.log(particlesChannel);
              if (particlesChannel.bus.Particles.length === 1 && particlesChannel.bus.FirstParticle().IsEvent()) {
                this.RunInputReceptors(particlesChannel, repMolecule, particleProcessed.data);
                this.inputstProcessed.push(particleProcessed.key);
                // timeout = 300;
                console.log('timeout, 300');
              }
              
              if (particlesChannel.bus.HasParticles()) {
                // console.log('data', particleProcessed.data);
                // setTimeout(
                //   () => {
                if (this.toolsService.GetObjectType(particleProcessed.data) !== 'filelist') {
                  particleProcessed.data = cloneDeep(particleProcessed.data) || null;
                }
                
                this.Process(
                  particleProcessed.key,
                  particlesChannel.bus.FirstParticle(),
                  this.busService.Get(particleProcessed.elementId),
                  particleProcessed.data,
                  particleProcessed.eventKey,
                );
                //   },
                //   this.slowdown ? 500 : timeout
                // );
              } else {
                this.StopProcessor(particlesChannel, repMolecule, particleProcessed.key, particleProcessed.data);
              }
            }
          }
        }
      },
    );
    
    this.communicationService.Event.System.DevTools.$ResumeBreakpointEvent.subscribe(metadata => {
      console.log('=event=');
      this.ProcessEvent(metadata.particle, metadata.key, metadata.eventKey, metadata.elementId);
    });
    
    this.communicationService.Event.System.DevTools.$ResumeBreakpointInitEvent.subscribe(metadata => {
      console.log('=event=');
      console.log(metadata.firstParticle);
      this.Process(metadata.key, metadata.firstParticle, this.busService.Get(metadata.elementId), metadata.data, metadata.eventKey);
    });
    
    this.communicationService.Event.System.DevTools.$ResumeBreakpointMolecule.subscribe(metadata => {
      console.log('=event=');
      this.ProcessMolecule(
        metadata.key,
        metadata.particle,
        this.busService.Get(metadata.elementId),
        metadata.data,
        metadata.eventKey,
        metadata.runParticleIndependent,
      );
    });
    
    this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent
    .pipe(
      map(e => {
        if (e.EventSource === 'Custom') {
          this.BufferEvent(e);
          return e;
        } else {
          this.ProcessEventCache(e);
        }
      }),
      debounceTime(100),
    )
    .subscribe(e => {
      this.GetAllBufferedEvents().forEach(event => {
        this.ProcessEventCache(event);
      });
      
      // this.ProcessEvent(this.GetBufferedEvent(e));
    });
  }
  
  BufferEvent(event: LeapXLEvent) {
    const key = `${ event.SourceId }${ event.EventType }${ event.EventName }${ event.EventSource }`;
    
    if (this.eventsBuffer[key]) {
      // exists
    } else {
      this.eventsBuffer[key] = event;
    }
    console.log(this.eventsBuffer);
  }
  
  GetAllBufferedEvents(): LeapXLEvent[] {
    const events = [];
    Object.keys(this.eventsBuffer).forEach(event => {
      events.push(this.eventsBuffer[event]);
    });
    
    this.eventsBuffer = {};
    
    return events;
  }
  
  GetBufferedEvent(event: LeapXLEvent): LeapXLEvent {
    let key = '';
    let eventBuffered = null;
    
    if (event) {
      key = `${ event.SourceId }${ event.EventType }${ event.EventName }${ event.EventSource }`;
      eventBuffered = this.eventsBuffer[key] || event;
    } else {
      key = Object.keys(this.eventsBuffer)[0];
      eventBuffered = this.eventsBuffer[key];
    }
    
    delete this.eventsBuffer[key];
    return eventBuffered;
  }
  
  ProcessEventCache(event: LeapXLEvent) {
    // console.log('$LeapXLEvent');
    
    if (event) {
    } else {
      return;
    }
    
    if (this.runtimeService.ChangingView && event.EventType !== 'View End' && event.EventSource !== 'Molecule') {
      this.runtimeService.AddEventToQueue(event);
      return;
    }
    
    console.log(`${ event.EventSource } - ${ event.SourceId } - ${ event.EventType }`);
    let busesToRun = this.eventsService.GetSpecificBusesForEvent(event);
    if (this.debug) {
      console.warn(`Event fired!`, event, busesToRun);
    }
    
    busesToRun = this.EvaluateEventsForDynamicRepMolecules(event, busesToRun);
    
    if (busesToRun) {
      this.fileService.mimetypes = [];
      busesToRun.buses.forEach(bus => {
        // console.log(`processor running...`);
        this.datasourceService.ClearDataSourceDataRequest(bus.bus.id);
        bus.bus.SetParticlesTriggeredBy();
        const outboundEvent = bus.bus.GetEventParticles().find(eventParticle => eventParticle.IsSameEvent(event));
        
        if (outboundEvent) {
          // avoid loop
          outboundEvent.AvoidRun = true;
        }
        
        this.Work(event, bus.bus, bus.repMoleculeId);
      });
    }
  }
  
  async Work(event: LeapXLEvent, bus: Bus, repMoleculeId: number) {
    const repmolecule = this.busService.Get(repMoleculeId.toString());
    
    repmolecule.HighlightRepMoleculeDebug(1);
    
    // avoid run process for table children not renderized
    // if (repmolecule.SubParentId > 0 && !repmolecule.Renderized) {
    //   return;
    // }
    
    if (bus.Receptor === Receptor.ValueInput || bus.Receptor === Receptor.ValuePreviewInput) {
      repmolecule.ValueMetaData = null;
      // repmolecule.PageNumber = 1;
    }
    
    console.log('representative molecule', repmolecule);
    console.log('particles to run', bus);
    
    const busId = this.toolsService.GenerateGuid();
    this.busService.AddBusToRunToProcessorChannel(busId, bus);
    
    console.log('key', busId);
    console.log('particles added', this.busService.ProcessorChannel);
    
    let firstParticle = {};
    
    if (bus.HasParticles()) {
      firstParticle = bus.FirstParticle().Clone() || Object.assign({}, bus.FirstParticle());
    }
    
    let data = event.Data;
    // cloning data to avoid override same data on different buses and rep molecules, could hurt performance
    if (this.toolsService.GetObjectType(event.Data) !== 'filelist') {
      data = cloneDeep(event.Data) || null;
    }
    
    // region handle dynamic rep molecules events data
    const dynamicRepMolecules = [RepresentativeMoleculesType.Stepper.toString()];
    const parentRepMolecule = this.busService.Get(event.SourceId.toString());
    if (event.EventType === LeapXLEventType.DataReceived && dynamicRepMolecules.includes(parentRepMolecule.Type)) {
      if (repmolecule && this.busService.IsMyChild(parentRepMolecule.Id, bus.RepresentativeMoleculeId)) {
        data = this.toolsService.ExtractValuesByType(data).array[repmolecule.SubParentId];
      }
    }
    
    // endregion
    
    // region PROCESS OUTPUT RECEPTOR
    const isIncomingEvent = event.Priority === 1 || event.TriggeredByBusId !== bus.id;
    
    // if incoming event data is null and receptor is output needs to take the value from the molecule
    if (isIncomingEvent && event.Data !== null) {
      // incoming event, do not set value
    } else if (isIncomingEvent && (event.Data === null || event.Data === undefined || event.Data === {})) {
      data = await this.RunOutputReceptor(bus.Receptor, repmolecule, data, bus);
    } else if (!isIncomingEvent) {
      data = await this.RunOutputReceptor(bus.Receptor, repmolecule, data, bus);
      event.Data = data;
    }
    // endregion
    
    // region EVENTS TOOL GUI
    const eventTransactionsWindows = document.getElementsByClassName('events-transaction-container');
    const processData = {
      event: event,
      busKey: busId,
      bus: bus,
      repMolecule: repmolecule,
    };
    
    if (this.cobbleService.Cobble.onEmulator) {
      this.runtimeService.EmulatorProcessorStart({
        userId: this.clientStorageService.getUserId(),
        appId: this.cobbleService.Cobble.id,
        processData,
      });
    }
    
    if (this.toolsService.RunningMode && eventTransactionsWindows.length > 0) {
      this.communicationService.Event.Runtime.MolecularEngine.$ProcessorStartRunning.emit(processData);
    }
    // endregion
    
    const eventKey = `${ event.EventSource }-${ event.SourceId }-${ event.EventType }`;
    
    // if first event running initializing bus
    if (!this.toolsService.IsEditor() && this.devToolsService.HasRuntimeBreakpointForBus(event.GlobalIdentifier(), bus.id) && event.Priority === 0) {
      const processMetadata: LeapProcessMetadata = {
        busId: bus.id,
        particle: event,
        key: busId,
        particleId: event.ParticleId,
        event: '',
        eventKey: eventKey,
        elementId: repmolecule.Id,
        data: data,
        endProccess: false,
        runParticleIndependent: false,
        init: true,
        firstParticle: firstParticle as ActionMolecule,
      };
      this.devToolsService.PauseProcess(processMetadata);
    } else {
      if (this.devToolsService.BusHasRuntimeBreakpoint(bus.id)) {
        this.devToolsService.AddHistory(event.GlobalIdentifier(), data);
      }
      
      if (repmolecule) {
        this.Process(busId, firstParticle, repmolecule, data, eventKey);
      }
    }
  }
  
  public Process(
    busKey: string,
    particle: Particle | any,
    repMolecule: IRepresentativeMolecule,
    data,
    eventKey = '',
    runParticleIndependent = false,
  ) {
    console.log('running particle', particle);
    // console.log('data', data);
    // console.log(repMolecule);
    
    if (repMolecule) {
      const processMetadata: LeapProcessMetadata = {
        init: false,
        firstParticle: null,
        busId: particle.TriggeredByBusId,
        particle: particle,
        key: busKey,
        particleId: particle.ParticleId,
        event: '',
        eventKey: eventKey,
        elementId: repMolecule.Id,
        data: data,
        endProccess: false,
        runParticleIndependent: runParticleIndependent,
      };
      
      switch (particle.ParticleType) {
        case ParticleType.Molecule:
          // disable molecule start breakpoint
          // if (this.devToolsService.HasBreakpoint(particle.ParticleId, particle.TriggeredByBusId)) {
          //   this.devToolsService.PauseProcess(processMetadata);
          // } else {
          // }
          this.ProcessMolecule(busKey, particle, repMolecule, data, eventKey, runParticleIndependent);
          break;
        case ParticleType.Event:
          (particle as LeapXLEvent).Data = data;
          
          this.CacheEventData(particle as LeapXLEvent);
          
          if (this.cobbleService.Cobble.onEmulator) {
            this.runtimeService.EmulatorParticleProcessed({
              userId: this.clientStorageService.getUserId(),
              appId: this.cobbleService.Cobble.id,
              processData: {
                key: busKey,
                particleId: particle.ParticleId,
                event: '',
                eventKey: eventKey,
                elementId: repMolecule.Id,
                data: data,
                endProccess: false,
              },
            });
          }
          (particle as LeapXLEvent).Data = this.GetEventCache(particle as LeapXLEvent);
          
          if ((particle as LeapXLEvent).AvoidRun) {
            // avoid loop
            console.warn('Event skipped to avoid loop', particle);
          } else {
            if (this.toolsService.RunningMode) {
              repMolecule.HighlightRepMoleculeDebug(2);
              // console.warn('event emitted', particle.GetName());
              if (this.devToolsService.HasRuntimeBreakpointOrStepOverForBus(new LeapXLEvent(particle).GlobalIdentifier(), particle.TriggeredByBusId)) {
                processMetadata.data = (particle as LeapXLEvent).Data;
                this.devToolsService.PauseProcess(processMetadata);
              } else {
                if (this.devToolsService.BusHasRuntimeBreakpoint(particle.TriggeredByBusId)) {
                  this.devToolsService.AddHistory(particle.GlobalIdentifier(), data);
                }
                this.ProcessEvent(particle, busKey, eventKey, repMolecule.Id.toString());
              }
            }
          }
          break;
        case ParticleType.DataElement:
          const dataKey = 'dataElementProcessor';
          this.datasourceService.dataSourceDataRequest[dataKey] = this.datasourceService.GetDataSourceRequestForKey(dataKey);
          this.datasourceService.dataSourceDataRequest[dataKey].translationIds = [[(particle as DataElement).TranslationId]];
          this.datasourceService.dataSourceDataRequest[dataKey].contexts = [[(particle as DataElement).Context]];
          this.datasourceService.dataSourceDataRequest[dataKey].offset = 0;
          this.datasourceService.GetDataSourceData(dataKey).subscribe(dataResult => {
            repMolecule.SetValueMetaData(dataResult.metaData, dataResult.dataKey);
            
            const processData = {
              key: busKey,
              particleId: particle.ParticleId,
              event: '',
              elementId: repMolecule.Id,
              data: dataResult.dataElements,
              endProccess: false,
            };
            
            this.communicationService.Event.Runtime.MolecularEngine.$ParticleProcessCompleted.emit(processData);
            
            if (this.cobbleService.Cobble.onEmulator) {
              this.runtimeService.EmulatorParticleProcessed({
                userId: this.clientStorageService.getUserId(),
                appId: this.cobbleService.Cobble.id,
                processData,
              });
            }
          });
          break;
        default:
          // console.warn('unknown particle', particle);
          this.RunInputReceptors(this.busService.GetBusToRunFromProcessorChannel(busKey), repMolecule, data);
          break;
      }
    } else {
      this.busService.RemoveBusFromProcessorChannel(busKey);
    }
  }
  
  ProcessEvent(particle: Particle, busKey: string, eventKey: string, repMoleculeId: string) {
    const processData = {
      key: busKey,
      particleId: particle.ParticleId,
      event: '',
      eventKey: eventKey,
      elementId: repMoleculeId,
      data: (particle as LeapXLEvent).Data,
      endProccess: false,
    };
    
    this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(new LeapXLEvent(particle));
    setTimeout(() => {
      this.communicationService.Event.Runtime.MolecularEngine.$ParticleProcessCompleted.emit(processData);
    }, 10);
  }
  
  ProcessMolecule(busKey: string, particle: Particle, repMolecule: IRepresentativeMolecule, data, eventKey = '', runParticleIndependent = false) {
    const moleculeEntity = this.GetActionMoleculeFunctionInstance(particle as ActionMolecule);
    
    // console.log('running molecule', moleculeEntity);
    if (repMolecule && moleculeEntity) {
      const actionMolecule = runParticleIndependent ? (particle as ActionMolecule) : (repMolecule.GetParticle(particle.ParticleId) as ActionMolecule);
      moleculeEntity.DataElements = actionMolecule ? actionMolecule.DataElements : [];
      moleculeEntity.RepMoleculeIds = actionMolecule ? actionMolecule.RepMoleculeIds : [];
      moleculeEntity.OriginalDataElements = actionMolecule ? actionMolecule.OriginalDataElements : [];
      moleculeEntity.EventKey = eventKey;
      moleculeEntity.MoleculeProcessDone = this.communicationService.Event.Runtime.MolecularEngine.$ParticleProcessCompleted;
      
      moleculeEntity.Process(
        runParticleIndependent,
        (particle as ActionMolecule).ParticleId,
        repMolecule ? repMolecule.Id.toString() : '0',
        busKey,
        (particle as ActionMolecule).Rule,
        (particle as ActionMolecule).Logic,
        data,
      );
    }
  }
  
  public Suspend(processInfo: any) {
    this.localStorageService.Set(
      'processor_molecules_suspended',
      JSON.stringify({
        info: processInfo,
        channel: this.busService.ProcessorChannel,
      }),
    );
  }
  
  StopProcessor(particlesChannel: { bus: Bus; data: any }, repMolecule: IRepresentativeMolecule, key: string, data: any) {
    const processData = {
      particlesChannel,
      repMolecule,
      key,
      data,
    };
    
    console.log('emulator', this.cobbleService.Cobble.onEmulator);
    if (this.cobbleService.Cobble.onEmulator) {
      this.runtimeService.EmulatorProcessorStop({
        userId: this.clientStorageService.getUserId(),
        appId: this.cobbleService.Cobble.id,
        processData,
      });
    }
    
    if (!this.inputstProcessed.includes(key)) {
      this.RunInputReceptors(particlesChannel, repMolecule, data);
    } else {
      this.inputstProcessed = this.inputstProcessed.filter(inputKey => inputKey !== key);
    }
    
    setTimeout(() => {
      this.communicationService.Event.Runtime.MolecularEngine.$StopProcessor.emit(processData);
    }, 40);
    
    this.busService.RemoveBusFromProcessorChannel(key);
    console.log('stop debugger');
    this.devToolsService.ClearDebugger(particlesChannel.bus.id);
  }
  
  async RunOutputReceptor(receptor: string, repMolecule: IRepresentativeMolecule, data: any, bus: any) {
    
    if (repMolecule.Type === RepresentativeMoleculesType.Custom && repMolecule.Properties.customControl.receptors.map(cr => cr.name).includes(bus.Receptor)) {
      
      
      if (this.toolsService.IsEditor()) {
        return;
      }
      
      if (!receptor.includes('output')) {
        return '';
      }
      
      data = await repMolecule.ProcessCustomReceptor(bus.Receptor, bus, data);
      bus.ReceptorData = data;
      return data;
    }
    
    switch (receptor) {
      case Receptor.ValueOutput:
      case Receptor.PageValueOutput:
      case Receptor.SelectStepOutput:
      case Receptor.TextOutput:
      case Receptor.SearchOutput:
      case Receptor.OptionsListOutput:
      case Receptor.StartDateOutput:
      case Receptor.EndDateOutput:
      case Receptor.MinDateOutput:
      case Receptor.MaxDateOutput:
      case Receptor.MinOutput:
      case Receptor.MaxOutput:
        data = await repMolecule.ProcessReceptor(bus.Receptor, bus);
        bus.ReceptorData = data;
        break;
      default:
        break;
    }
    
    return data;
  }
  
  RunInputReceptors(particlesChannel: { bus: Bus; data: any }, repMolecule: IRepresentativeMolecule, data: any) {
    if (particlesChannel.bus.Receptor.indexOf('input') === -1) {
      return;
    }
    
    if (repMolecule) {
      
      if (repMolecule.Type === RepresentativeMoleculesType.Custom && repMolecule.Properties.customControl.receptors.map(cr => cr.name).includes(particlesChannel.bus.Receptor)) {
        repMolecule.ProcessCustomReceptor(particlesChannel.bus.Receptor, particlesChannel.bus, data);
        return;
      }
      
      switch (particlesChannel.bus.Receptor) {
        case Receptor.ValueInput:
        case Receptor.BadgeValueInput:
        case Receptor.SelectStepInput:
        case Receptor.ValuePreviewInput:
        case Receptor.TextInput:
        case Receptor.TextLoading:
        case Receptor.SearchInput:
        case Receptor.HeaderInput:
        case Receptor.TooltipInput:
        case Receptor.OptionsListInput:
        case Receptor.MinDateInput:
        case Receptor.MaxDateInput:
        case Receptor.StartDateInput:
        case Receptor.EndDateInput:
        case Receptor.MinInput:
        case Receptor.MaxInput:
          repMolecule.ProcessReceptor(particlesChannel.bus.Receptor, particlesChannel.bus, data);
          break;
        default:
          break;
      }
    } else {
      console.error('RunInputReceptors: rep molecule undefined', particlesChannel, data);
    }
  }
  
  public Resume() {
    if (this.localStorageService.Get('processor_suspended') && JSON.parse(this.localStorageService.Get('processor_suspended'))) {
      console.log('resuming processor');
      const processorMoleculesSuspended = JSON.parse(this.localStorageService.Get('processor_molecules_suspended'));
      
      if (new Date().getTime() - processorMoleculesSuspended.date < 1000 * 120) {
        if (this.cobbleService.Cobble.id === processorMoleculesSuspended.cobbleId) {
          
          processorMoleculesSuspended.channel.forEach(ch => {
            ch.value.bus = new Bus(ch.value.bus);
          });
          
          this.busService.SetMoleculesChannel(processorMoleculesSuspended.channel);
          
          const processData = processorMoleculesSuspended.info;
          
          this.communicationService.Event.Runtime.MolecularEngine.$ParticleProcessCompleted.emit(processData);
          
          if (this.cobbleService.Cobble.onEmulator) {
            this.runtimeService.EmulatorParticleProcessed({
              userId: this.clientStorageService.getUserId(),
              appId: this.cobbleService.Cobble.id,
              processData,
            });
          }
          
          this.localStorageService.Remove('processor_molecules_suspended');
          this.localStorageService.Remove('processor_suspended');
        }
      } else {
        this.localStorageService.Remove('processor_molecules_suspended');
        this.localStorageService.Remove('processor_suspended');
      }
    }
  }
  
  CacheEventData(event: LeapXLEvent) {
    if (event.Data) {
      const key = `${ event.SourceId }${ event.EventType }${ event.EventName }${ event.EventSource }`;
      
      if (this.EventCacheExists(event)) {
        const storedData = this.eventCache[key];
        const storedDataType = this.toolsService.GetObjectType(storedData);
        const commingDataType = this.toolsService.GetObjectType(event.Data);
        
        switch (commingDataType) {
          case 'array':
            if (storedDataType === 'array' && storedData.length <= event.Data.length) {
              this.SetEventCache(key, event.Data);
            }
            break;
          default:
            this.SetEventCache(key, event.Data);
            break;
        }
      } else {
        this.SetEventCache(key, event.Data);
      }
    }
  }
  
  SetEventCache(key: string, data: any) {
    this.eventCache[key] = data;
    setTimeout(() => {
      this.ClearEventCache(key);
      // console.log('event cache timeout 500');
    }, 500);
  }
  
  ClearEventCache(key: string) {
    delete this.eventCache[key];
  }
  
  GetEventCache(event: LeapXLEvent) {
    const key = `${ event.SourceId }${ event.EventType }${ event.EventName }${ event.EventSource }`;
    return this.eventCache[key];
  }
  
  EventCacheExists(event: LeapXLEvent): boolean {
    const key = `${ event.SourceId }${ event.EventType }${ event.EventName }${ event.EventSource }`;
    return !!this.eventCache[key];
  }
  
  EvaluateEventsForDynamicRepMolecules(
    event: LeapXLEvent,
    busesToRun: {
      moleculeId: string;
      buses: {
        bus: Bus;
        repMoleculeId: number;
      }[];
    },
  ) {
    try {
      // min dynamic id
      const minId = 11111111;
      // dynamic molecules
      const dynamicTypes = [RepresentativeMoleculesType.Stepper.toString()];
      
      if (event.SourceId < minId || event.EventSource !== 'Molecule' || isNaN(+event.SourceId)) {
        // not event from dynamic mols
        return busesToRun;
      }
      
      const repMoleculeEvent = this.busService.Get(event.SourceId.toString());
      const repMoleculeParent = this.busService.Get(repMoleculeEvent.ParentId.toString());
      
      if (dynamicTypes.includes(repMoleculeParent.Type)) {
        const deniedRepMolecules = [];
        
        const repMoleculesListening = busesToRun.buses.map(b => b.repMoleculeId);
        
        repMoleculesListening.forEach(repMolId => {
          const repMoleculeListening = this.busService.Get(repMolId.toString());
          
          if (repMoleculeListening.ParentId === repMoleculeEvent.ParentId) {
            if (repMoleculeListening.SubParentId === repMoleculeEvent.SubParentId) {
              // good to go
            } else {
              deniedRepMolecules.push(repMoleculeListening.Id);
            }
          } else {
            // good to go
          }
        });
        
        busesToRun.buses = busesToRun.buses.filter(b => !deniedRepMolecules.includes(b.repMoleculeId));
        return busesToRun;
      } else {
        return busesToRun;
      }
    } catch (err) {
      console.error(err);
      return busesToRun;
    }
  }
  
  public GetActionMoleculeFunctionInstance(actionMolecule: ActionMolecule): ActionMoleculeFunction {
    const moleculeExists = ActionMoleculesInstances[actionMolecule.InternalMoleculeName];
    
    if (moleculeExists) {
      return new ActionMoleculesInstances[actionMolecule.InternalMoleculeName](
        actionMolecule,
        this.http,
        this.errorMessengerService,
        this.communicationService,
        this.cacheService,
        this.toolsService,
        this.snackerService,
      );
    } else {
      const compatibilityMolecule = this.templateService.RetrocompatibilityMolecules[actionMolecule.InternalMoleculeName];
      return new ActionMoleculesInstances[compatibilityMolecule](
        actionMolecule,
        this.http,
        this.errorMessengerService,
        this.communicationService,
        this.cacheService,
        this.toolsService,
        this.snackerService,
      );
    }
  }
}
