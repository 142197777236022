import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { RuntimeService } from '../../../running-area/services/runtime.service';
import { DatasourceType } from '../../../shared/enums/datasource-type.enum';
import { Bus } from '../../../shared/representative-molecule/interfaces/bus';
import { LeapXLEvent } from '../../../shared/representative-molecule/interfaces/leapxl-event';
import { CobbleService } from '../../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { ErrorMessengerService } from '../../../shared/services/error-messenger.service';
import { FactoryParticleService } from '../../../shared/services/factory-particle.service';
import { WorkAreaService } from '../../../workarea/workarea.service';
import { ApiDataService } from '../../services/api-data.service';
import { RepresentativeMoleculesType } from './../../../shared/enums/representative-molecules-types.enum';
import { BusService } from './bus.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends ApiDataService implements OnDestroy {
  public eventsTable = {};
  subscriptions: Subscription = new Subscription();
  CustomEvents: LeapXLEvent[] = [];
  private leapXLCommunications = {
    Types: {
      Completed: 'Completed',
      Success: 'Success',
      Next: 'Next',
      Update: 'Update',
      Save: 'Save',
      Delete: 'Delete',
      Cancel: 'Cancel',
      Error: 'Error',
      Custom: 'LeapXLCommunicationsEvent',
    },
  };
  
  constructor(
    http: HttpClient,
    private communicationService: CommunicationService,
    errorMessengerService: ErrorMessengerService,
    private busService: BusService,
    private workAreaService: WorkAreaService,
    protected cobbleService: CobbleService,
    private runtimeService: RuntimeService,
    private factoryParticleService: FactoryParticleService,
  ) {
    super('events', http, errorMessengerService);
    this.subscriptions.add(
      this.communicationService.Event.Editor.$RecreateEventsTable.pipe(debounceTime(200))
      .subscribe(() => {
        console.log('=event=');
        this.CreateEventsTable();
      }),
    );
  }
  
  GetViewElementsEvents(): string[] {
    const moleculeEvents = [];
    const elements = this.busService.GetWorkareaViewMolecules(this.cobbleService.Cobble,
      this.workAreaService.actualEditorViews);
    
    elements.forEach(e => {
      e.Events.forEach(event => {
        moleculeEvents.push(
          `${ e.Properties.name } - ${ event
          .split('-')
          .map(en => en.toUpperCase())
          .join(' ') }`,
        );
      });
    });
    
    return moleculeEvents;
  }
  
  GetViewEvents() {
    return [`View Change`, 'View Start', 'View End', 'View Broadcast'];
  }
  
  GetSystemEvents() {
    return [
      {
        name: 'App Init',
        icon: '',
      },
      {
        name: 'App Broadcast',
        icon: '',
      },
      {
        name: 'Datasource Change',
        icon: '',
      },
      {
        name: 'Interim Volunteer',
        icon: 'switch_access_shortcut',
      },
      {
        name: 'Inbound External Datasource',
        icon: '',
      },
    ];
  }
  
  GetLeapXLCommunicationEvents() {
    return ['Completed', `Success`, 'Next', 'Update', 'Save', 'Delete', 'Cancel', 'Error'];
  }
  
  RemoveFromEventsTable(repMoleculeIds: number[] = []) {
    repMoleculeIds.forEach(repMoleculeId => {
      const repMolecule = this.busService.Get(repMoleculeId.toString());
      
      repMolecule.Buses.filter(b => b.Enabled)
      .forEach(eBus => {
        this.RemoveBusForEvent(
          `${ (eBus.FirstParticle() as any).EventSource }-${
            (eBus.FirstParticle() as any).EventSource === 'LeapXLCommunication' ? '' : (eBus.FirstParticle() as any).SourceId || repMolecule.Id
          }-${ (eBus.FirstParticle() as any).EventType }`,
        );
      });
    });
  }
  
  AddCustomEvent(customEvent: LeapXLEvent) {
    if (this.CustomEventExists(customEvent)) {
    } else {
      this.CustomEvents.push(customEvent);
      this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit();
    }
  }
  
  AddAndSaveCustomEvent(customEvent: LeapXLEvent) {
    if (!this.CustomEventExists(customEvent)) {
      this.AddCustomEvent(customEvent);
      this.SaveEvent([customEvent])
      .subscribe();
    }
  }
  
  AddToEventsTable(repMoleculeIds: number[] = []) {
    repMoleculeIds.forEach(repMoleculeId => {
      const repMolecule = this.busService.Get(repMoleculeId.toString());
      
      repMolecule.Events.forEach(event => {
        const newEventId = `Molecule-${ repMolecule.Id }-${ event }`;
        
        delete this.eventsTable[newEventId];
      });
    });
    
    repMoleculeIds.forEach(repMoleculeId => {
      const repMolecule = this.busService.Get(repMoleculeId.toString());
      
      let buses = repMolecule.Buses.filter(b => b.Enabled);
      
      // if(repMolecule.SubParentId > 0){
      //   const originalRepMolecule = this.busService.Get(repMolecule.OriginalRepMoleculeId.toString());
      //   buses = buses.concat(originalRepMolecule.Buses);
      // }
      
      // console.log(buses);
      buses.forEach(eBus => {
        const busCopy = eBus.Clone();
        // busCopy.AssingId();
        
        if (eBus.HasParticles() && eBus.FirstParticle()
        .IsEvent()) {
          let sourceId = (eBus.FirstParticle() as any).SourceId;
          
          if (sourceId) {
            if (sourceId.toString() === repMolecule.OriginalRepMoleculeId.toString()) {
              sourceId = repMolecule.Id;
            }
          } else {
            sourceId = repMolecule.Id;
          }
          
          busCopy.Particles = busCopy.Particles.slice(1);
          
          const eventId = `${ (eBus.FirstParticle() as LeapXLEvent).EventSource }-${
            (eBus.FirstParticle() as any).EventSource === 'LeapXLCommunication' ? '' : sourceId
          }-${ (eBus.FirstParticle() as any).EventType }`;
          
          this.AddBusForEvent(eventId, busCopy, sourceId, repMolecule.Id,
            eBus.FirstParticle() as LeapXLEvent);
        }
      });
    });
    
    repMoleculeIds.forEach(repMoleculeId => {
      const repMolecule = this.busService.Get(repMoleculeId.toString());
      
      // replace original bus events
      repMolecule.Events.forEach(event => {
        const originalEventId = `Molecule-${ repMolecule.OriginalRepMoleculeId }-${ event }`;
        const newEventId = `Molecule-${ repMolecule.Id }-${ event }`;
        const originalBusesForEvent = this.GetBusesForEvent(originalEventId);
        const newEventBuses = {
          moleculeId: repMolecule.Id,
          buses: originalBusesForEvent.buses,
        };
        
        if (originalBusesForEvent) {
          newEventBuses.moleculeId = repMolecule.Id;
          
          const newBusEvents = this.GetBusesForEvent(newEventId);
          
          if (newBusEvents.moleculeId.toString() === repMolecule.Id.toString()) {
            newEventBuses.buses.forEach(b => {
              if (!this.BusExists(newBusEvents.buses, b)) {
                newBusEvents.buses.push(b);
              }
            });
          } else {
            if (newEventBuses.buses.length > 0) {
              this.eventsTable[newEventId] = newEventBuses;
            }
          }
        }
      });
    });
    
    this.GenerateEventsDSTable();
  }
  
  CustomEventExists(event: LeapXLEvent) {
    const eventFound = this.CustomEvents.find(ce => ce.SourceId === event.SourceId);
    return !!eventFound;
  }
  
  CreateEventsTable(onlyForView = false): any {
    console.log('creating events table');
    this.eventsTable = {};
    
    let workgroups = [];
    
    if (!this.cobbleService.Cobble.running) {
      const lockedView = this.cobbleService.Cobble.properties.views.find(v => v.locked);
      
      if (this.workAreaService.actualEditorViews.length > 0) {
        const viewIds = this.workAreaService.actualEditorViews.filter(v => v !== undefined)
        .map(v => v.id);
        workgroups = this.busService
        .DirectChildrenElements(this.cobbleService.Cobble.id)
        .filter(
          m =>
            viewIds.includes(m.Properties.view) || (lockedView && m.Properties.view === lockedView.id),
        );
      }
      
    } else {
      workgroups =
        onlyForView || !this.cobbleService.Cobble.running
          ? this.busService.GetViewWorkgroupsMolecules(this.cobbleService.Cobble,
            this.runtimeService.ActualViewId)
          : this.busService.GetWorkgroupsMolecules(this.cobbleService.Cobble);
    }
    
    console.log(workgroups);
    
    workgroups.forEach(w => {
      w.Buses.filter(b => b.Enabled)
      .forEach((wgBus: Bus) => {
        if (wgBus.HasParticles() && wgBus.FirstParticle()
        .IsEvent()) {
          const busCopy = wgBus.Clone();
          busCopy.Particles = busCopy.Particles.slice(1);
          busCopy.AssingId();
          
          this.AddBusForEvent(
            `${ (wgBus.FirstParticle() as LeapXLEvent).EventSource }-${
              (wgBus.FirstParticle() as any).EventSource === 'LeapXLCommunication' ? '' : (wgBus.FirstParticle() as any).SourceId
            }-${ (wgBus.FirstParticle() as any).EventType }`,
            busCopy,
            (wgBus.FirstParticle() as any).SourceId,
            w.Id,
            wgBus.FirstParticle() as LeapXLEvent,
          );
        }
      });
      
      this.busService.ChildrenElements(w.Id)
      .forEach(wgChild => {
        // console.log(wgChild.Id);
        if (wgChild.SubParentId === 0 || (wgChild.SubParentId > 0 && wgChild.Id >= 11111111)) {
          wgChild.Buses.filter(b => b.Enabled)
          .forEach(eBus => {
            const busCopy = eBus.Clone();
            busCopy.AssingId();
            
            if (eBus.HasParticles() && eBus.FirstParticle()
            .IsEvent()) {
              busCopy.Particles = busCopy.Particles.slice(1);
              
              this.AddBusForEvent(
                `${ (eBus.FirstParticle() as LeapXLEvent).EventSource }-${
                  (eBus.FirstParticle() as any).EventSource === 'LeapXLCommunication' ? '' : (eBus.FirstParticle() as any).SourceId || wgChild.Id
                }-${ (eBus.FirstParticle() as any).EventType }`,
                busCopy,
                (eBus.FirstParticle() as any).SourceId || wgChild.Id,
                wgChild.Id,
                eBus.FirstParticle() as LeapXLEvent,
              );
            } else {
              // adding any repmol bus to be able to launch from the editor
              if (!this.runtimeService.RunningMode && eBus.HasParticles()) {
                this.AddBusForEvent(
                  `Molecule-${ (eBus.FirstParticle() as LeapXLEvent).SourceId || wgChild.Id }-${ wgChild.Id }${ eBus.id }`,
                  busCopy,
                  (eBus.FirstParticle() as any).SourceId || wgChild.Id,
                  wgChild.Id,
                  eBus.FirstParticle() as LeapXLEvent,
                );
              }
              //
            }
          });
          
          if (wgChild.Type === RepresentativeMoleculesType.Stepper) {
            this.busService.ChildrenElements(wgChild.Id)
            .forEach(stepperChild => {
              // console.log(wgChild.Id);
              if (stepperChild.SubParentId === 0 || (stepperChild.SubParentId > 0 && stepperChild.Id >= 11111111)) {
                stepperChild.Buses.forEach(eBus => {
                  const busCopy = eBus.Clone();
                  busCopy.AssingId();
                  
                  if (eBus.HasParticles() && eBus.FirstParticle()
                  .IsEvent()) {
                    busCopy.Particles = busCopy.Particles.slice(1);
                    
                    this.AddBusForEvent(
                      `${ (eBus.FirstParticle() as LeapXLEvent).EventSource }-${
                        (eBus.FirstParticle() as any).EventSource === 'LeapXLCommunication'
                          ? ''
                          : (eBus.FirstParticle() as any).SourceId || stepperChild.Id
                      }-${ (eBus.FirstParticle() as any).EventType }`,
                      busCopy,
                      (eBus.FirstParticle() as any).SourceId || stepperChild.Id,
                      stepperChild.Id,
                      eBus.FirstParticle() as LeapXLEvent,
                    );
                  } else {
                    // adding any repmol bus to be able to launch from the editor
                    if (!this.runtimeService.RunningMode && eBus.HasParticles()) {
                      this.AddBusForEvent(
                        `Molecule-${ (eBus.FirstParticle() as LeapXLEvent).SourceId || stepperChild.Id }-${ stepperChild.Id }${ eBus.id }`,
                        busCopy,
                        (eBus.FirstParticle() as any).SourceId || stepperChild.Id,
                        stepperChild.Id,
                        eBus.FirstParticle() as LeapXLEvent,
                      );
                    }
                    //
                  }
                });
                
                if (stepperChild.Type === RepresentativeMoleculesType.WorkGroup || stepperChild.Type === RepresentativeMoleculesType.Table) {
                  this.busService.ChildrenElements(stepperChild.Id)
                  .forEach(stepperWgChild => {
                    console.log('event', stepperWgChild.Type);
                    
                    // console.log(wgChild.Id);
                    if (stepperWgChild.SubParentId === 0 || (stepperWgChild.SubParentId > 0 && stepperWgChild.Id >= 11111111)) {
                      stepperWgChild.Buses.forEach(stepperWgChildBus => {
                        const busCopy = stepperWgChildBus.Clone();
                        busCopy.AssingId();
                        
                        if (stepperWgChildBus.HasParticles() && stepperWgChildBus.FirstParticle()
                        .IsEvent()) {
                          busCopy.Particles = busCopy.Particles.slice(1);
                          
                          this.AddBusForEvent(
                            `${ (stepperWgChildBus.FirstParticle() as LeapXLEvent).EventSource }-${
                              (stepperWgChildBus.FirstParticle() as any).EventSource === 'LeapXLCommunication'
                                ? ''
                                : (stepperWgChildBus.FirstParticle() as any).SourceId || stepperWgChild.Id
                            }-${ (stepperWgChildBus.FirstParticle() as any).EventType }`,
                            busCopy,
                            (stepperWgChildBus.FirstParticle() as any).SourceId || stepperWgChild.Id,
                            stepperWgChild.Id,
                            stepperWgChildBus.FirstParticle() as LeapXLEvent,
                          );
                        } else {
                          // adding any repmol bus to be able to launch from the editor
                          if (!this.runtimeService.RunningMode && stepperWgChildBus.HasParticles()) {
                            this.AddBusForEvent(
                              `Molecule-${ (stepperWgChildBus.FirstParticle() as LeapXLEvent).SourceId || stepperWgChild.Id }-${ stepperWgChild.Id }${
                                stepperWgChildBus.id
                              }`,
                              busCopy,
                              (stepperWgChildBus.FirstParticle() as any).SourceId || stepperWgChild.Id,
                              stepperWgChild.Id,
                              stepperWgChildBus.FirstParticle() as LeapXLEvent,
                            );
                          }
                          //
                        }
                      });
                      
                      this.busService.ChildrenElements(stepperWgChild.Id)
                      .forEach(stepperWgChild2 => {
                        // console.log(wgChild.Id);
                        if (stepperWgChild2.SubParentId === 0 || (stepperWgChild2.SubParentId > 0 && stepperWgChild2.Id >= 11111111)) {
                          stepperWgChild2.Buses.forEach(stepperWgChild2Bus => {
                            const busCopy = stepperWgChild2Bus.Clone();
                            busCopy.AssingId();
                            
                            if (stepperWgChild2Bus.HasParticles() && stepperWgChild2Bus.FirstParticle()
                            .IsEvent()) {
                              busCopy.Particles = busCopy.Particles.slice(1);
                              
                              this.AddBusForEvent(
                                `${ (stepperWgChild2Bus.FirstParticle() as LeapXLEvent).EventSource }-${
                                  (stepperWgChild2Bus.FirstParticle() as any).EventSource === 'LeapXLCommunication'
                                    ? ''
                                    : (stepperWgChild2Bus.FirstParticle() as any).SourceId || stepperWgChild2.Id
                                }-${ (stepperWgChild2Bus.FirstParticle() as any).EventType }`,
                                busCopy,
                                (stepperWgChild2Bus.FirstParticle() as any).SourceId || stepperWgChild2.Id,
                                stepperWgChild2.Id,
                                stepperWgChild2Bus.FirstParticle() as LeapXLEvent,
                              );
                            } else {
                              // adding any repmol bus to be able to launch from the editor
                              if (!this.runtimeService.RunningMode && stepperWgChild2Bus.HasParticles()) {
                                this.AddBusForEvent(
                                  `Molecule-${ (stepperWgChild2Bus.FirstParticle() as LeapXLEvent).SourceId || stepperWgChild2.Id }-${
                                    stepperWgChild2.Id
                                  }${ stepperWgChild2Bus.id }`,
                                  busCopy,
                                  (stepperWgChild2Bus.FirstParticle() as any).SourceId || stepperWgChild2.Id,
                                  stepperWgChild2.Id,
                                  stepperWgChild2Bus.FirstParticle() as LeapXLEvent,
                                );
                              }
                              //
                            }
                          });
                        }
                      });
                    }
                  });
                }
              }
            });
          }
        }
        // if (wgChild.MoleculeSubType === RepresentativeMoleculesType.Table) {
        //   // console.log('table children');
        //
        //   this.busService.ChildrenElements(wgChild.Id).forEach((tChild) => {
        //     tChild.Buses.forEach((tChildBus) => {
        //       if (
        //         tChildBus.HasParticles() &&
        //         tChildBus.Particles[0].IsEvent()
        //       ) {
        //         const busCopy = tChildBus.Clone();
        //         busCopy.Particles = busCopy.Particles.slice(1);
        //
        //         // console.log('adding tchild event', tChild.Id);
        //
        //         this.AddBusForEvent(
        //           `${(tChildBus.Particles[0] as any).EventSource}-${
        //             tChild.Id
        //           }-${(tChildBus.Particles[0] as any).EventType}`,
        //           busCopy,
        //           (tChildBus.Particles[0] as any).SourceId,
        //           tChild.Id
        //         );
        //       }
        //     });
        //   });
        // }
      });
    });
    
    this.GenerateEventsDSTable();
  }
  
  GenerateEventsDSTable() {
    const datasourceProcess = {};
    
    Object.keys(this.eventsTable)
    .forEach((key: any) => {
      const eventData = this.eventsTable[key];
      
      const eventDs = {
        event: key,
        ds: {
          addMolecules: [],
          updateMolecules: [],
          deleteMolecules: [],
        },
      };
      
      eventData.buses
      .filter(b => b.bus.Enabled)
      .forEach(b => {
        eventDs.ds.addMolecules = eventDs.ds.addMolecules.concat(
          (b.bus as Bus)
          .GetActionMoleculeParticlesByInternalName('AddToDatasourceMolecule')
          .filter(am => !am.IncludesContext(DatasourceType.LocalDataStore)),
        );
        eventDs.ds.updateMolecules = eventDs.ds.updateMolecules.concat(
          (b.bus as Bus)
          .GetActionMoleculeParticlesByInternalName('UpdateDatasourceDataMolecule')
          .filter(am => !am.IncludesContext(DatasourceType.LocalDataStore)),
        );
        eventDs.ds.deleteMolecules = eventDs.ds.deleteMolecules.concat(
          (b.bus as Bus)
          .GetActionMoleculeParticlesByInternalName('DeleteDatasourceDataMolecule')
          .filter(am => !am.IncludesContext(DatasourceType.LocalDataStore)),
        );
      });
      
      datasourceProcess[key] = eventDs.ds;
    });
    
    console.log('datasourceProcess', datasourceProcess);
    
    this.runtimeService.EventsDS = datasourceProcess;
    
    return this.eventsTable;
  }
  
  GetSpecificBusesForEvent(event: LeapXLEvent): {
    moleculeId: string;
    buses: {
      bus: Bus;
      repMoleculeId: number;
    }[];
  } {
    const eventBuses = this.GetBusesForEvent(
      `${ event.EventSource }-${ event.SourceId }-${ event.EventType }`);
    const newEventBuses = {
      moleculeId: eventBuses.moleculeId,
      buses: [],
      debounce: {
        events: [],
      },
    };
    
    if (event.EventSource === 'Molecule' && event.SourceId >= 11111111) {
      const repMolecule = this.busService.Get(event.SourceId);
      
      if (repMolecule) {
        const cellData = repMolecule.CellDataElement;
        newEventBuses.buses = eventBuses.buses.filter(
          b => b.row === (cellData ? cellData.row : null) || b.row === null);
      }
    } else {
      newEventBuses.buses = eventBuses.buses;
    }
    
    return newEventBuses;
  }
  
  GetBusesForEvent(eventId: string): {
    moleculeId: string;
    buses: {
      bus: Bus;
      repMoleculeId: number;
      row: number;
    }[];
  } {
    return (
      this.eventsTable[eventId] || {
        moleculeId: 0,
        buses: [],
        debounce: {
          events: [],
        },
      }
    );
  }
  
  RemoveCustomEvents(customEvents: LeapXLEvent[]) {
    this.CustomEvents = this.CustomEvents.filter(e => !customEvents.map(ce => ce.ParticleId)
    .includes(e.ParticleId));
    console.log(this.CustomEvents);
    this.http
    .post(this.apiEndpointUrl + '/delete', {
      events: customEvents,
      applicationId: this.cobbleService.Cobble.id,
    })
    .pipe(
      map(response => {
        return (response = <any>response);
      }),
    )
    .subscribe();
  }
  
  SaveEvents() {
    return this.SaveEvent(this.CustomEvents);
  }
  
  SaveEvent(events: LeapXLEvent[]) {
    events.forEach(e => {
      const createdEvent = this.CustomEvents.find(ce => ce.EventName === e.EventName);
      if (createdEvent) {
        createdEvent.SourceId = e.SourceId;
      }
    });
    
    return this.http
    .post(this.apiEndpointUrl, {
      events,
      applicationId: this.cobbleService.Cobble.id,
    })
    .pipe(
      map(response => {
        return (response = <any>response);
      }),
    );
  }
  
  RenameEvent(oldName: string, newName: string) {
    return this.http
    .put(this.apiEndpointUrl, {
      oldName,
      newName,
      applicationId: this.cobbleService.Cobble.id,
    })
    .pipe(
      map(response => {
        return (response = <any>response);
      }),
    );
  }
  
  GetEventsForApp() {
    return this.http.post(this.apiEndpointUrl + '/GetByApplication/' + this.cobbleService.Cobble.id, {})
    .pipe(
      map(response => {
        return (response as []).map((e: any) => (e = new LeapXLEvent(e)));
      }),
    );
  }
  
  HandleLeapXLCommunicationsEvents(data: {
    event: string;
    eventData: string;
    url: string;
    appRuntimeId: string;
    hostAppSpecific: boolean;
    companySlug: string;
    appSlug: string;
  }) {
    const runtimeInformation = this.runtimeService.GetRuntimeInformation();
    
    if (data.hostAppSpecific) {
      if (runtimeInformation.appRuntimeId === data.appRuntimeId) {
        this.TriggerLeapXlCommunicationsEvent(data);
      }
    } else {
      if (runtimeInformation.companySlug === data.companySlug && runtimeInformation.slug === data.appSlug) {
        this.TriggerLeapXlCommunicationsEvent(data);
      }
    }
  }
  
  TriggerLeapXlCommunicationsEvent(data: any) {
    let event = null;
    
    if (data.source && data.source > 0) {
      event = this.factoryParticleService.GenerateEvent(data.event, 'Molecule', data.source);
    } else {
      event = this.factoryParticleService.GenerateEvent(data.event, 'LeapXLCommunication');
    }
    
    event.Data = data.eventData;
    this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(event);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  BusExists(buses: any[], bus: any) {
    const busId = `${ bus.repMoleculeId }-${ bus.bus.id }`;
    
    const busesIds = buses.map(b => {
      return `${ b.repMoleculeId }-${ b.bus.id }`;
    });
    
    return busesIds.includes(busId);
  }
  
  RemoveFromListening(repMoleculeIds: number[]) {
    Object.keys(this.eventsTable)
    .forEach(key => {
      this.eventsTable[key].buses = this.eventsTable[key].buses.filter(
        b => !repMoleculeIds.includes(b.repMoleculeId));
    });
  }
  
  private RemoveBusForEvent(eventId: string) {
    delete this.eventsTable[eventId];
  }
  
  private AddBusForEvent(eventId: string, bus: Bus, moleculeId: any, repMoleculeId: number, event: LeapXLEvent) {
    if (!bus.Enabled) {
      return;
    }
    
    const repMolecule = this.busService.Get(repMoleculeId.toString());
    
    const newBus = {
      bus: bus,
      repMoleculeId: repMoleculeId,
      row: repMolecule.CellDataElement ? repMolecule.CellDataElement.row : null,
    };
    const particlesForEvent = this.GetBusesForEvent(eventId);
    
    if (!this.BusExists(particlesForEvent.buses, newBus)) {
      particlesForEvent.buses.push(newBus);
      this.eventsTable[eventId] = {
        moleculeId: +moleculeId,
        buses: particlesForEvent.buses,
        event: event,
      };
    }
  }
}
