import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSelect } from '@angular/material/select';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, flatMap, map } from 'rxjs/operators';
import { BuilderService } from '../../../../core/builder/builder.service';
import { Receptor } from '../../../../core/molecular/receptors.enum';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { ApiPropertiesService } from '../../../../core/services/api-properties.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { PropertyVersioningDto } from '../../../dtos/versioning-dto';
import { LeapXLEventType } from '../../../enums/leapxl-event-type.enum';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DataElement } from '../../interfaces/data-element';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-dropdown-molecule',
  templateUrl: './dropdown-molecule.component.html',
  styleUrls: ['./dropdown-molecule.component.scss'],
})
export class DropdownMoleculeComponent
  extends BaseMoleculeComponent
  implements OnInit, OnDestroy, AfterViewInit {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Dropdown;
  public editOption = new Subject<any>();
  
  @ViewChild('dropdownWrapper', { static: true })
  dropdownWrapper: ElementRef;
  @ViewChild('searchFieldInput', { static: false })
  searchFieldInput: ElementRef;
  @ViewChild('select', { static: false }) selectionList: MatSelect;
  dropdownValue = null;
  filteredOptions = [];
  public filterSearch = new Subject<any>();
  dropdownKeySelection = new Subject<MatSelect>();
  subscriptions: Subscription;
  private scrollTopBeforeSelection: number;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public propertiesService: ApiPropertiesService,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  get ValueOptions(): any[] {
    const repMolecule = this.busService.Get(this.context.Id.toString());
    
    const inputListBus = repMolecule.GetBusesByReceptor(Receptor.OptionsListInput);
    
    if (inputListBus.length !== 1) {
      return this.context.ValueOptions;
    }
    
    const takeDataElementsMolecules = inputListBus[0].GetActionMoleculeParticlesByInternalName(
      'FilterByDataElementReferenceMolecule');
    
    if (takeDataElementsMolecules.length === 0) {
      return this.context.ValueOptions;
    }
    
    const takeDataElementsMolecule = takeDataElementsMolecules[0];
    const datasource = takeDataElementsMolecule.DataElements[0];
    
    if (!datasource) {
      return this.context.ValueOptions;
    }
    
    return this.context.ValueOptions.filter(vo => new DataElement().FromContext(vo.context)
    .BelongsToContext(datasource.Context));
  }
  
  ngOnInit() {
    super.ngOnInit();
    this.context.Type = RepresentativeMoleculesType.Dropdown;
    this.RefreshGridsterConfiguration();
    this.editOption
    .pipe(
      map((event) => event.target.value),
      debounceTime(300),
      flatMap((search) => of(search)
      .pipe(delay(150))),
    )
    .subscribe((value) => {
      this.saveOptions();
    });
    
    // listen for search field value changes
    this.filterSearch
    .pipe(
      map((event) => {
        event.preventDefault();
        event.stopPropagation();
        return (event.target.value =
          event.keyCode === 32
            ? event.target.value + ' '
            : event.target.value);
      }),
      debounceTime(100),
      flatMap((search) => of(search)
      .pipe(delay(50))),
    )
    .subscribe((value) => {
      this.context.SearchFilter = value;
      this.FireRepresentativeMoleculeEvent(
        LeapXLEventType.Search,
        this.context.SearchFilter,
        true,
      );
      this.FilterOptions();
    });
    
    this.subscriptions = this.dropdownKeySelection.pipe(
      debounceTime(800),
    )
    .subscribe(select => this.FireSelectedEvent(select));
    
    this.UpdateData(false);
  }
  
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  
  UpdateData(fireEvent = true) {
    if (this.context.Value) {
      const splittedValue = this.context.Value.replaceAll(', ', ',')
      .split(',');
      
      if (this.context.Properties.multiSelect) {
        this.dropdownValue = splittedValue;
      } else {
        this.dropdownValue = this.context.Value;
      }
      
      if (fireEvent) {
        this.FireSelectedEvent(
          null,
          0,
          this.context.Properties.multiSelect
            ? this.context.Value.replaceAll(', ', ',')
            .replaceAll(',', ', ')
            : this.context.Value,
          false,
        );
      }
    } else {
      if (this.context.Properties.multiSelect) {
        this.dropdownValue = [];
      } else {
        this.dropdownValue = null;
      }
    }
    
    this.filteredOptions = this.ValueOptions;
    
    if (this.searchFieldInput) {
      this.searchFieldInput.nativeElement.value = this.context.SearchFilter;
    }
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(
      this.dropdownWrapper.nativeElement,
      'dragover',
      (evt) => {
        this.drag(evt, true);
      },
    );
    const dragleaveEventListener = this.renderer.listen(
      this.dropdownWrapper.nativeElement,
      'dragover',
      (evt) => {
        this.drag(evt, false);
      },
    );
    const dropEventListener = this.renderer.listen(
      this.dropdownWrapper.nativeElement,
      'drop',
      (evt) => {
        this.DataDropped(evt);
      },
    );
  }
  
  ngAfterViewInit() {
    // setTimeout(() => {
    this.SetDropdownSelectionColor();
    
    // workaround to avoid undesired scroll on select after selecting an option
    if (this.selectionList) {
      this.selectionList.openedChange.subscribe((open) => {
        if (open) {
          this.selectionList.panel.nativeElement.addEventListener(
            'scroll',
            (event) => (this.scrollTopBeforeSelection = event.target.scrollTop),
          );
        }
      });
      
      this.selectionList.optionSelectionChanges.subscribe(() => {
        if (this.selectionList.panel) {
          this.selectionList.panel.nativeElement.scrollTop =
            this.scrollTopBeforeSelection;
        }
      });
    }
    //
    // }, 50);
  }
  
  SetDropdownSelectionColor() {
    if (
      this.context.Properties.responsive[this.cobbleService.Cobble.deviceType]
        .font.fontColor
    ) {
      const htmlElement = document.querySelector(
        `#gridsterItem-${ this.context.Id } .mat-select-value-text`,
      ) as any;
      if (htmlElement && this.context.Properties.enable) {
        htmlElement.style['color'] =
          this.context.Properties.responsive[
            this.cobbleService.Cobble.deviceType
            ].font.fontColor;
      }
    }
  }
  
  DropdownToggle(open: any) {
    if (open) {
      (document.querySelector('.mat-select-panel') as any).style.maxHeight =
        (this.context.Properties.increment * 38).toString() + 'px';
      
      if (this.context.Properties.search && this.searchFieldInput) {
        this.searchFieldInput.nativeElement.focus();
        this.searchFieldInput.nativeElement.select();
      }
    }
  }
  
  saveOptions() {
    this.context
    .SavePropertyFromVersioning(
      new PropertyVersioningDto({
        elementId: this.context.Id.toString(),
        property: 'options',
        value: this.context.Properties.options,
        path: 'properties',
        change: 'Edit Options',
        name: this.context.Properties.name,
      }),
    )
    .subscribe();
  }
  
  FireSelectedEvent(selectTemplate: MatSelect, index = 0, data = null, fireEvent = true) {
    let optionSelected = null;
    let optionDeselected = null;
    
    if (data) {
      this.context.Value = optionSelected = data;
    } else {
      if (this.context.Properties.multiSelect) {
        const previousValues = this.context.Value
          ? this.context.Value.replaceAll(', ', ',')
          .split(',')
          : [];
        const newValues = selectTemplate.value;
        
        if (newValues.length < previousValues.length) {
          optionSelected = null;
          optionDeselected = previousValues.filter(
            (x) => !newValues.includes(x),
          )[0];
        } else {
          optionSelected = newValues.filter(
            (x) => !previousValues.includes(x),
          )[0];
        }
        
        this.context.Value = selectTemplate.value.join(', ');
      } else {
        optionDeselected = this.context.Value;
        this.context.Value = selectTemplate.value;
        optionSelected = this.context.Value;
      }
    }
    
    setTimeout(() => {
      this.SetDropdownSelectionColor();
    }, 50);
    
    if (optionDeselected) {
      this.FireRepresentativeMoleculeEvent(
        LeapXLEventType.OptionDeselected,
        optionDeselected,
        true,
      );
    }
    
    console.log(this.context.Properties.allowEmptyValues);
    
    if (
      !this.context.Properties.allowEmptyValues &&
      (this.context.Value === null || this.context.Value === '')
    ) {
    } else {
      optionSelected = optionSelected ?? '';
      
      if (fireEvent) {
        this.FireRepresentativeMoleculeEvent(
          LeapXLEventType.OptionDatasetSelected,
          this.GetSelectedDataset(optionSelected, index),
          true,
        );
        this.FireRepresentativeMoleculeEvent(
          LeapXLEventType.OptionSelected,
          optionSelected,
          true,
        );
        this.FireRepresentativeMoleculeEvent(
          LeapXLEventType.OptionsSelected,
          this.context.Value,
          true,
        );
      }
    }
  }
  
  OptionMouseMove(event: MouseEvent) {
    this.isHoverVisible = true;
    const parentStyle = (event.target as any).parentElement.style;
    const style = (event.target as any).style;
    parentStyle.color = this.context.Properties.hover.hoverFontColor;
    parentStyle.textDecoration = 'none';
    style.color = this.context.Properties.hover.hoverFontColor;
    style.textDecoration = this.context.Properties.hover.hoverTextDecoration;
  }
  
  OptionMouseLeave(event: MouseEvent) {
    this.isHoverVisible = false;
    const parentStyle = (event.target as any).parentElement.style;
    const style = (event.target as any).style;
    
    parentStyle.color =
      this.context.Properties.responsive[
        this.cobbleService.Cobble.deviceType
        ].font.fontColor;
    parentStyle.textDecoration = this.context.Properties.textDecoration;
    style.color =
      this.context.Properties.responsive[
        this.cobbleService.Cobble.deviceType
        ].font.fontColor;
    style.textDecoration = this.context.Properties.textDecoration;
  }
  
  GetSelectedDataset(value: string, index: number): any[] {
    const rowsSelectedIndex = this.ValueOptions[index].row;
    return this.context.ValueOptions.filter(vo => vo.row === rowsSelectedIndex);
  }
  
  FilterOptions(filter: string = null) {
    if (!this.ValueOptions) {
      return;
    }
    // get the search keyword
    if (!this.context.SearchFilter && !filter) {
      this.filteredOptions = this.ValueOptions;
      return;
    } else {
      this.context.SearchFilter = filter || this.context.SearchFilter;
      
      const search = this.context.SearchFilter.toLowerCase();
      // filter the banks
      this.filteredOptions = this.ValueOptions.filter(
        (option) =>
          option.value &&
          option.value.toString()
          .toLowerCase()
          .indexOf(search) > -1,
      );
      console.log(this.filteredOptions);
    }
  }
}
