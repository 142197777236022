import { Component, NgZone, OnInit } from '@angular/core';
import { BusService } from '../../core/molecular/services/bus.service';
import { ToolsService } from '../../core/services/tools.service';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-datasource-connected',
  templateUrl: './datasource-connected.component.html',
  styleUrls: ['./datasource-connected.component.scss'],
})
export class DatasourceConnectedComponent implements OnInit {
  node: any;
  repMolecules: IRepresentativeMolecule[] = [];
  views = [];
  contexts = [];
  
  constructor(
    private draggableWindowService: DraggableWindowService,
    private toolsService: ToolsService,
    private cobbleService: CobbleService,
    private workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
    private busService: BusService,
    private ngZone: NgZone,
  ) {
  }
  
  ngOnInit() {
    this.node = this.draggableWindowService.GetData().node;
    
    this.ngZone.runOutsideAngular(() => {
      this.GetContexts(this.node);
      
      this.contexts.forEach((context) => {
        const repMoleculeContexts = this.busService.GetRepresentativeMoleculesWithContext(context);
        repMoleculeContexts.forEach((repMol) => {
          if (!this.repMolecules.includes(repMol)) {
            this.repMolecules.push(repMol);
          }
        });
      });
      
      const viewsRepMol = this.GetRepMoleculesByView();
      
      this.ngZone.run(() => {
        this.views = viewsRepMol;
      });
    });
  }
  
  GetContexts(node: any) {
    if (node) {
      this.contexts.push(node.path);
      
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          this.GetContexts(child);
        });
      } else {
        return;
      }
    } else {
      return;
    }
  }
  
  GetRepMoleculesByView() {
    const views = [];
    
    this.cobbleService.Cobble.properties.views.forEach((view) => {
      const viewMols = {
        view: view.name,
        repMolecules: this.repMolecules.filter((repMolecule) => repMolecule.Properties.view === view.id),
      };
      
      views.push(viewMols);
    });
    
    return views;
  }
  
  HighlightRepMolecule(repMolecule: IRepresentativeMolecule) {
    if (repMolecule.Type === RepresentativeMoleculesType.WorkGroup) {
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(repMolecule);
    } else {
      const parentRepMolecule = this.busService.Get(repMolecule.ParentId.toString());
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(parentRepMolecule);
    }
    
    setTimeout(() => {
      this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
      this.workAreaService.ShowElementFocusedMenu(repMolecule);
    }, 200);
  }
}
